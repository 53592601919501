import React, { useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import { format } from 'date-fns';
import { datePicker } from '_fields/inputs';
import Indicator from '../Indicator';
import { SsnInput } from '../SsnInput.1';
import { withStyles } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { required } from '_fields/validation';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import ContinueButton from '@components/_shared/Continue/Button';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { Stack } from 'shared-components';

const styles = {
  paper: {
    padding: '10px',
  },
  popover: {
    pointerEvents: 'none',
  },
  typography: {
    padding: '25px',
    width: '320px',
    fontSize: '12px',
    textAlign: 'justify',
    position: 'relative',
  },
};

const EnhancedIdentity = ({ dob, ownerIdx, change, classes, handleSubmit, match, invalid, saving }) => {
  const continueButton =
    match.params.property === 'add' ? null : (
      <ContinueButton invalid={invalid} saving={saving} data-testid="question-submit-button" />
    );

  const form = useForm();
  console.log('ownerIdx', ownerIdx);
  const [selectedDate, setSelectedDate] = useState(dob ? format(new Date(dob), 'MM/dd/yyyy') : null);
  const [openedPopoverId, setOpenedPopoverId] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const fieldKey = `owner_${ownerIdx}`;

  const handleDateChange = (date) => {
    // prevent attempting to format invalid or null dates (allows manual editing)
    if (date == 'Invalid Date' || date === null) {
      change(`${fieldKey}_dob`, null);
      return;
    }
    const dbDate = format(date, 'yyyy-MM-dd');
    const pickerDate = format(date, 'MM/dd/yyyy');
    setSelectedDate(pickerDate);

    change(`${fieldKey}_dob`, dbDate);
  };

  const handleClick = (event, popoverId) => {
    setOpenedPopoverId(popoverId);
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  return (
    <div className="app-section slide">
      <Form {...form}>
        <form onSubmit={handleSubmit}>
          <Stack gapVariant="content-to-submit-button">
            <Stack gapVariant="head-section-to-content">
              <Helmet>
                <title>Owner Identity - Your Loan Application</title>
              </Helmet>
              <FormPageTopPart headingText="Identity Confirmation" subHeadingText="Please provide the following:" />
              <Stack gapVariant="input-to-input">
                <Indicator ownerIdx={ownerIdx} />

                <Field
                  component={datePicker}
                  placeholder="Date of Birth"
                  dateValue={selectedDate}
                  handleDateChange={handleDateChange}
                  className="input-100"
                  type="tel"
                  name={`owner_${ownerIdx}_dob`}
                  validate={[required]}
                  tabIndex="-1"
                />

                <SsnInput change={change} ownerIdx={ownerIdx} />
                <Stack templateColumns="1fr" alignItems="center" justifyItems="center">
                  <div
                    className="max-w-[368px] w-full align-center justify-center justify-items-center disclosure-title max-w-[368px]"
                    onClick={(e) => handleClick(e, 'disclosure-popover')}
                  >
                    Why do you need this?
                  </div>
                </Stack>
                <Popover
                  id="disclosure-popover"
                  open={openedPopoverId === 'disclosure-popover'}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.typography}>
                    We use your social security number to validate your information and check your credit. This will NOT
                    affect your credit score. Your information is transmitted securely.
                    <CloseIcon className="close-popover" onClick={handleClose} />
                  </Typography>
                </Popover>
              </Stack>
            </Stack>
            {continueButton}
          </Stack>
        </form>
      </Form>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state, ownProps) => {
  return {
    dob: selector(state, `owner_${ownProps.ownerIdx}_dob`),
    ssn: selector(state, `owner_${ownProps.ownerIdx}_ssn`),
  };
};

const Identity = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(connect(mapStateToProps)(EnhancedIdentity));

const EnhancedIdentityWrapped = withStyles(styles)(Identity);

export default EnhancedIdentityWrapped;
