import { change } from 'redux-form';
import * as styles from '@styles/styles.scss';
import { axiosInstance } from '../api/axiosInstance';
import { changePriceToNumber } from '../utils/changePriceToNumber';
import { Dispatch } from 'redux';
import { RootState } from '@store/reducers';
import { backendUrl } from '@utils/platformBasedInfo';
import { ApplyWizardStepObject } from '@components/ApplyWizard/ApplyWizardSteps';
import { Opportunity } from '../types';

export const SET_THEME = 'SET_THEME';
export const CLEAR_THEME = 'CLEAR_THEME';
export const SET_TEMP = 'SET_TEMP';
export const CLEAR_TEMP = 'CLEAR_TEMP';
export const SET_DATA = 'SET_DATA';
export const CLEAR_DATA = 'CLEAR_DATA';

export const SET_CONFIG = 'SET_CONFIG';
export const CLEAR_CONFIG = 'CLEAR_CONFIG';
export const SET_VALIDATON_TYPE = 'SET_VALIDATON_TYPE';
export const LOAD_VENDOR_STARTED = 'LOAD_VENDOR_STARTED';
export const LOAD_VENDOR_SUCCESS = 'LOAD_VENDOR_SUCCESS';
export const LOAD_VENDOR_FAILURE = 'LOAD_VENDOR_FAILURE';
export const LOAD_VENDOR_DEFAULT = 'LOAD_VENDOR_DEFAULT';

export const SET_USER = 'SET_USER';
export const AUTH_FAILED = 'AUTH_FAILED';
export const LOGOUT = 'LOGOUT';
export const REFRESH = 'REFRESH';
export const REFRESH_USER_STARTED = 'REFRESH_USER_STARTED';
export const REFRESH_USER_SUCCESS = 'REFRESH_USER_SUCCESS';
export const REFRESH_USER_FAILURE = 'REFRESH_USER_FAILURE';

export const SET_SOCURE_DEVICE_ID = 'SET_SOCURE_DEVICE_ID';

export function setTheme(theme: {
  primary?: string;
  primaryRgba?: string;
  secondary?: string;
  logoPosition?: string;
  logoScale?: string;
  name?: string;
}) {
  return {
    type: SET_THEME,
    theme: theme,
  };
}

const setCustomTheme = (dispatch: Dispatch, state: RootState) => {
  const brandTheme = state?.brand?.data?.theme;
  const theme = {
    primary: brandTheme?.primary_color,
    primaryRgba: brandTheme?.primary_rgba,
    secondary: styles.default.secondary,
    logoPosition: 'left', // brandTheme?.logo?.position, // CENTER DISABLED FOR NOW
    logoScale: '100', // brandTheme?.logo?.scale_percent, // SCALING DISABLED FOR NOW
    name: 'default',
  };

  (window as any).themePrimary = theme.primary;
  dispatch(setTheme(theme));
};

export function clearTheme() {
  return {
    type: CLEAR_THEME,
  };
}

export const loadVendor = (vendor: string) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(loadVendorStarted());

    axiosInstance
      .post(`${backendUrl}/vendor/config`, { vendor })
      .then(function (res) {
        (window as any).gtag('event', 'Vendor Load on /go URL', {
          event_category: 'Dynamic Vendor',
          event_label: res.data.name,
          value: res.data.name,
        });
        document.title = res.data.name;
        dispatch(loadVendorSuccess(res.data));
        const state = getState();
        const config = {
          customVendor: true,
          loaded: true,
          ...res.data,
        };
        updateVendorFormValues(state, config, dispatch, vendor);
        setCustomTheme(dispatch, state);
      })
      .catch((err) => {
        dispatch(loadVendorFailure(err.message));
      });
  };
};
type LoanType = {
  rate: number;
  min: number;
  max: number;
  amount: number;
  termOptions: number[];
  selectedTerm: number;
};
export type ConfigType = {
  id: string;
  logo: string;
  loaded: boolean;
  customVendor: boolean;
  error?: string | null;
  name?: string;
  EQ: LoanType;
  WC: LoanType;
};
const loadVendorSuccess = (config: ConfigType) => ({
  type: LOAD_VENDOR_SUCCESS,
  payload: {
    ...config,
  },
});

const loadVendorStarted = () => ({
  type: LOAD_VENDOR_STARTED,
});

const loadVendorFailure = (error: string) => ({
  type: LOAD_VENDOR_FAILURE,
  payload: {
    error,
  },
});

const updateVendorFormValues = (state: RootState, config: ConfigType, dispatch: Dispatch, vendor?: string) => {
  let defaultType: 'EQ' | 'WC' | 'LOC' | 'BCC' | 'PL' = 'EQ';

  if (config.name === 'iFunding' || vendor === 'bankrate' || vendor === 'woocommerce' || vendor === 'spscommerce') {
    defaultType = 'WC';
    const WC = { ...config[defaultType], termOptions: ['3', '6', '12'] } as any;
    dispatch(setConfig({ ...config, WC }));
  }
  const configAmount = config[defaultType].amount;
  const configTerms = config[defaultType].selectedTerm;

  if (state.form.application && state.form.application.values) {
    const loanAmount = state.form.application.values.loan_amount;
    const loanTerms = state.form.application.values.loan_terms;
    if (
      state.form?.application?.values?.loan_type &&
      Array.isArray(state.form.application.values.loan_type) &&
      state.form.application.values.loan_type.some((val: Opportunity['type']) => val === 'WC')
    ) {
      defaultType = 'WC';
    }
    const loanType = state.form.application.values.loan_type;
    const loanTypeSafe = Array.isArray(loanType) ? (loanType.length > 0 ? loanType : ['WC']) : ['WC'];
    dispatch(change('application', 'loan_type', loanTypeSafe));

    // if redux has some values but amount or terms are NOT set, go ahead and set from config
    if (!loanAmount) {
      dispatch(change('application', 'loan_amount', changePriceToNumber(configAmount)));
    }
    if (!loanTerms) {
      dispatch(change('application', 'loan_terms', configTerms));
    }

    // if redux values match the default, override them with custom config values
    if (config.customVendor) {
      if (loanAmount === defaultVendor(state)[defaultType].amount) {
        dispatch(change('application', 'loan_amount', changePriceToNumber(configAmount)));
      }
      if (loanTerms === defaultVendor(state)[defaultType].selectedTerm) {
        dispatch(change('application', 'loan_terms', configTerms));
      }
    }
  } else {
    // if state.form.application is empty we definitely need to set
    dispatch(change('application', 'loan_amount', changePriceToNumber(configAmount)));
    dispatch(change('application', 'loan_terms', configTerms));
  }
};

export const AVAILABLE_TERM_OPTIONS = [24, 36, 48, 60];

const defaultVendor = (state: RootState) => ({
  id: state.brand?.data?.vendor_id,
  logo: state.brand?.data?.media?.logo_url,
  loaded: true, // default to true, used for custom vendor loads
  customVendor: false,
  EQ: {
    rate: 8,
    min: Number(state.brand.data?.loan_amount?.eq?.minimum) || 5000,
    max: Number(state.brand.data?.loan_amount?.eq?.maximum) || 150000,
    amount: state.brand.data?.loan_amount?.eq?.default ? Number(state.brand.data?.loan_amount?.eq?.default) : 100000,
    termOptions: AVAILABLE_TERM_OPTIONS,
    selectedTerm: 60,
  },
  WC: {
    rate: 10,
    min: Number(state.brand.data?.loan_amount?.wc?.minimum) || 5000,
    max: Number(state.brand.data?.loan_amount?.wc?.maximum) || 250000,
    amount: state.brand.data?.loan_amount?.wc?.default ? Number(state.brand.data?.loan_amount?.wc?.default) : 150000,
    termOptions: AVAILABLE_TERM_OPTIONS,
    selectedTerm: 6,
  },
});

export const loadDefaultVendor = (force = false) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.config.customVendor || force) {
      dispatch(loadVendorDefault(defaultVendor(state)));
      updateVendorFormValues(state, defaultVendor(state), dispatch);
      setCustomTheme(dispatch, state);
    }
  };
};

const loadVendorDefault = (config: ConfigType) => ({
  type: LOAD_VENDOR_DEFAULT,
  payload: {
    ...config,
  },
});

export function setConfig(config: ConfigType) {
  return {
    type: SET_CONFIG,
    config: config,
  };
}

export function clearConfig() {
  return {
    type: CLEAR_CONFIG,
  };
}
export type ValidationType = 'text' | 'email';
export function setValidationType(type: string) {
  return {
    type: SET_VALIDATON_TYPE,
    signInType: { type },
  };
}
export type UserType = {
  user: {
    loggedIn?: boolean;
    name: string;
    id?: string;
    accountId?: string;
    email?: string;
    image?: string;
    token?: string;
    expires?: number;
  };
};
export function setUser(data: UserType) {
  if (data.user && data.user.token) {
    return {
      type: SET_USER,
      user: data.user,
    };
  }

  return {
    type: AUTH_FAILED,
  };
}

export function setSocureDeviceId(deviceId: string) {
  return {
    type: SET_SOCURE_DEVICE_ID,
    payload: deviceId,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export const refreshUser = (user: UserType['user']) => {
  return (dispatch: Dispatch, state: RootState) => {
    // dispatch(refreshUserStarted(user));

    const headers = user && user.token ? { Authorization: 'Bearer ' + user.token } : {};

    // TODO: this requires reimplementation using Auth0 refresh
    // axiosInstance
    //   .post(`${backendUrl}/user/refresh`, user, { headers })
    //   .then(function (res) {
    //     dispatch(refreshUserSuccess(res.data));
    //   })
    //   .catch((err) => {
    //     dispatch(refreshUserFailure(err.message));
    //   });
  };
};

const refreshUserSuccess = (user: UserType['user']) => ({
  type: REFRESH_USER_SUCCESS,
  payload: {
    ...user,
  },
});

const refreshUserStarted = (user: UserType['user']) => ({
  type: REFRESH_USER_STARTED,
  payload: {
    ...user, // need to pass existing user object during refresh to void clearing completely
  },
});

const refreshUserFailure = (error: string) => ({
  type: REFRESH_USER_FAILURE,
  payload: {
    error,
  },
});
export type LoanOffer = {
  uuid: string;
  originator: {
    images: { url: string }[];
    name: string;
    disclaimer: string;
  };
  maxAmount: number;
  termLength: number;
  termUnit: string;
  minApr: number;
  aprType: string;
  url: string;
};
export type TempType = {
  offer?: {
    loanType: 'EQ' | 'WC' | 'LOC' | 'BCC' | 'PL';
    rate: number;
    terms: {
      [key: number]: {
        factor: number;
        payments: number;
      };
    };
    monthly: boolean;
  };
  haveStatements?: boolean;
  wcApprovedAmountFloored?: boolean;
  pendingOffers?: {
    pendingResponses: {
      partner: {
        uuid: string;
        imageUrl: string;
      };
    }[];
  };
  offersLoaded?: boolean;
  offers?: {
    loanOffers: LoanOffer[];
  };
  signingResult?: string;
};
export const setTemp = (data: TempType) => {
  return {
    type: SET_TEMP,
    data,
  };
};

export function clearTemp() {
  return {
    type: CLEAR_TEMP,
  };
}

export function setData(data: any) {
  return {
    type: SET_DATA,
    deals: data.deals,
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}
export type LogoPositionType = 'center' | 'left';

export type BrandType = {
  data: {
    vendor_id: string;
    vendor_url: string;
    vendor_name: string;
    route_home: string;
    route_prefix: string;
    loan_amount: {
      wc: {
        minimum: string;
        maximum: string;
        default: string;
      };
      eq: {
        minimum: string;
        maximum: string;
        default: string;
      };
    };
    media: {
      favicon_url: string;
      logo_url: string;
      icon_url: string;
      icon_white_url: string;
    };
    theme: {
      primary_color: string;
      primary_rgba: string;
      hover_color: string;
      logo: {
        scale_percent: string;
        position: LogoPositionType;
      };
      font_sizes: {
        t1: string;
        t2: string;
        t2LineH: string;
        t3: string;
        t4: string;
        t4LineH: string;
        t5: string;
        t5LineH: string;
        s0: string;
        s1: string;
        s2: string;
        s3: string;
        s4: string;
        inputPlaceholderSize: string;
        inputErrorSize: string;
      };
      font_family: string;
    };
    routes: string[];
  };
  loading: boolean;
  error: boolean | string;
};
export const setBrand = (data: BrandType) => ({
  type: 'SET_BRAND',
  payload: data,
});
export const setFarthestStep = (data: ApplyWizardStepObject) => ({
  type: 'SET_FARTHEST_STEP',
  payload: data,
});

export const setUserSellectedToCreateNewDeal = (data: any) => ({
  type: 'SET_USER_SELLECTED_TO_CREATE_NEW_DEAL',
  payload: data,
});

export const setHiddenRoutes = (data: string[]) => ({
  type: 'SET_HIDDEN_ROUTES',
  payload: data,
});

export const addRoutesToHide = (data: any) => ({
  type: 'ADD_ROUTES_TO_HIDE',
  payload: data,
});

export const setBrandRoutes = (data: any) => ({
  type: 'SET_ROUTES',
  payload: data,
});

export const setBrandFailure = (error: any) => ({
  type: 'SET_BRAND_FAILURE',
  payload: {
    error,
  },
});
