import { MouseEventHandler, SetStateAction, SyntheticEvent } from 'react';
import { LoanStage } from 'shared-components';

export type LoanType = 'EQ' | 'WC' | 'LOC' | 'BCC' | 'PL';
export type SavingType = boolean;
export type CallApiType = (
  route: string,
  data: any,
  vendor_n: string,
  {
    method,
    baseUrl,
  }?: {
    method?: string;
    baseUrl: string;
  }
) => Promise<any>;
export type NextRouteType = (replace?: boolean | undefined) => void;
export type SetSavingType = SetStateAction<boolean>;
export type OpenAlertType = (message: string, level?: string, processing?: boolean, showFeedback?: boolean) => void;
export type CloseAlertType = (
  event?: MouseEventHandler<HTMLButtonElement> | undefined | SyntheticEvent<any>,
  reason?: string
) => void;
export type DataLayerPushType = (event: any, post: any) => void;
export type ShowProcessingModalType = (title: string, msg?: string | null) => void;

export enum LoanTypeResponse {
  EQ = 'EQ',
  WC = 'WC',
  LOC = 'LOC',
  BCC = 'BCC',
  PL = 'PL',
}
export type DealsType = {
  id: number;
  amount: number | null;
  stage: LoanStage | null;
  type: LoanTypeResponse | null;
  termLength: number | null;
  termLengthAgreed: number | null;
  termUnit: string | null;
  termUnitAgreed: string | null;
  equipmentDesc: string | null;
};

export type Contact = {
  id: number;
  publicId: string;
  userId: number | null;
  crmRecordId: string | null;
  accountId: number;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  dob: Date | null;
  jobTitle: string | null;
  ownershipPercentage: number | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  ssn: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type Account = {
  id: number;
  publicId: string;
  crmRecordId: string | null;
  mainUserId: number | null;
  referralPartnerId: number | null;
  name: string;
  tib: number | null;
  inceptionDate: string | null;
  doingBusinessAs: string | null;
  annualRevenue: number | null;
  phoneMain: string | null;
  phoneMobile: string | null;
  email: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
  isHomeBased: boolean | null;
  naicsCode: string | null;
  naicsDescription: string | null;
  entityType: string | null;
  taxIdNumber: string | null;
  website: string | null;
  employeeCount: number | null;
  ip: string | null;
  plaidAccessToken: string | null;
  plaidAssetReportToken: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export type Opportunity = {
  id: number;
  publicId: string;
  crmRecordId: string | null;
  userId: number | null;
  name: string | null;
  accountId: number;
  amount: number | null;
  stage: LoanStage | null;
  terms: string | null;
  type: string | null;
  timeInBusiness: number | null;
  equipmentDesc: string | null;
  submittedAt: Date | null;
  submittedAmount: number | null;
  submittedTermLength: number | null;
  submittedTermUnit: string | null;
  closedAt: Date | null;
  closedAmount: number | null;
  closedBuyRate: number | null;
  closedTermLength: number | null;
  closedTermUnit: string | null;
  referralPartnerId: number | null;
  referralPartnerCommission: number | null;
  createdAt: Date;
  updatedAt: Date;
};

export type DealFormattedResponse = {
  id: Opportunity['publicId'];
  stage: Opportunity['stage'];
  amount: Opportunity['amount'];
  termLength: Opportunity['submittedTermLength'];
  termUnit: Opportunity['submittedTermUnit'];
  equipmentDesc: Opportunity['equipmentDesc'];
  termLengthAgreed: Opportunity['closedTermLength'];
  bankStatements?: 'email' | 'upload' | 'plaid';
  termUnitAgreed: Opportunity['closedTermUnit'];
  type: Opportunity['type'];
};

export type SourceFormattedResponse = {
  partnerId: string;
  brandId: string;
  campaign?: {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
  };
  customFields?: {
    [a: string]: string;
  };
};

export type ContactFormattedResponse = {
  id?: Contact['publicId'];
  isMainContact?: boolean;
  firstName?: Contact['firstName'];
  lastName?: Contact['lastName'];
  title?: Contact['jobTitle'];
  email?: Contact['email'];
  dob?: String | null;
  ssn?: Contact['ssn'];
  phoneHome?: Contact['mobile'] | null; // TODO: phone home does not exist on Contact
  phoneMobile?: Contact['mobile'];
  ownerPercentage?: Contact['ownershipPercentage'];
  address?: {
    address1?: Contact['address'];
    address2?: Contact['address']; // TODO: address2 does not exist on Contact
    city?: Contact['city'];
    state?: Contact['state'];
    postalCode?: Contact['zip'];
  } | null;
  home_based?: boolean; // Only if first owner is home based.
};

export type BusinessFormattedResponse = {
  id?: Account['publicId'];
  name?: Account['name'];
  doingBusinessAs?: Account['doingBusinessAs'];
  entityType?: Account['entityType'];
  taxIdNumber?: Account['taxIdNumber'];
  naicsCode?: Account['naicsCode'];
  naicsDescription?: Account['naicsDescription'];
  annualRevenue?: Account['annualRevenue'];
  businessInceptionDate?: Account['inceptionDate'];
  businessYears?: Account['tib'];
  phone?: Account['phoneMain'];
  mobile?: Account['phoneMobile'];
  email?: Account['email'];
  website?: Account['website'];
  employees?: Account['employeeCount'];
  address?: {
    address1?: Account['address1'];
    address2?: Account['address2'];
    city?: Account['city'];
    state?: Account['state'];
    postalCode?: Account['postalCode'];
  };
  contacts?: ContactFormattedResponse[];
  home_based?: boolean;
};

export type EntireDealFormattedResponse = {
  id?: Opportunity['publicId'];
  loanRequest?: Partial<DealFormattedResponse>;
  userId?: string;
  product?: LoanType | null;
  source?: Partial<SourceFormattedResponse>;
  business?: BusinessFormattedResponse;
};

export enum DecisionBusiness {
  ProceedWC = 'business.proceed.wc',
  ProceedEQ = 'business.proceed.eq',
  ProceedPersonal = 'business.proceed.personal',
  DeclinedFrozen = 'business.declined.frozen',
  DeclinedError = 'business.declined.error',
  DeclinedTimeInBusiness = 'business.declined.tib',
  DeclinedFico = 'business.declined.fico',
  DeclinedRevenue = 'business.declined.revenue',
  DeclinedGeneral = 'business.declined.general',
}

export enum DecisionPersonal {
  ProceedWC = 'personal.proceed.wc',
  ProceedEQ = 'personal.proceed.eq',
  DeclinedFrozen = 'personal.declined.frozen',
  DeclinedError = 'personal.declined.error',
  DeclinedTimeInBusiness = 'personal.declined.tib',
  DeclinedFico = 'personal.declined.fico',
  DeclinedRevenue = 'personal.declined.revenue',
  DeclinedGeneral = 'personal.declined.general',
}
