import React, { FC } from 'react';
import { reduxForm, Field } from 'redux-form';
import { muiCustomSelect, newThemeInput } from '_fields/inputs';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { RootState } from '@store/reducers';
import { Stack } from 'shared-components';
import { SsnInput } from '@components/Apply/Owners/SsnInput.1';
import { change } from 'redux-form';
import { InputAdornment } from '@material-ui/core';
import { formatOwnershipPercentage, lowerCase, phone, words } from '_fields/normalizers';
import Address from '@components/_shared/Address/Address';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { ApplyWizardSteps, STEP_BUSINESS_OWNERS } from '@components/ApplyWizard/ApplyWizardSteps';

type OwnerFieldsProps = {
  ownerNr: number;
  change: (e: any, a: string) => void;
  withAllFields?: boolean;
  street?: string;
  suite?: string;
  city?: string;
  state?: string;
  zip?: string;
};

const OwnerFieldsWrapper2: FC<OwnerFieldsProps> = ({
  ownerNr,
  change,
  street,
  suite,
  city,
  state,
  zip,
  withAllFields = true,
}) => {
  return (
    <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
      {withAllFields && (
        <>
          <Stack
            flow="column"
            templateColumns="1fr 1fr"
            className="gap-2 w-full max-w-[368px]"
            gapVariant="input-to-input"
            alignItems="start"
          >
            <Field
              data-testid={`owner-${ownerNr}-first`}
              name={`owner_${ownerNr}_first`}
              component={newThemeInput}
              type="text"
              label="First Name*"
              placeholder="First Name*"
              normalize={words}
            />
            <Field
              data-testid={`owner-${ownerNr}-last`}
              name={`owner_${ownerNr}_last`}
              component={newThemeInput}
              type="text"
              label="Last Name*"
              placeholder="Last Name*"
              normalize={words}
            />
          </Stack>
          <Stack
            flow="column"
            templateColumns="1fr 1fr"
            className="gap-2 w-full max-w-[368px]"
            gapVariant="input-to-input"
            alignItems="start"
          >
            <Field
              data-testid={`owner-${ownerNr}-mobile`}
              name={`owner_${ownerNr}_mobile`}
              component={newThemeInput}
              type="tel"
              label="Mobile Phone*"
              placeholder="Mobile Phone*"
              formatAsPhone
            />

            <Field
              data-testid={`owner-${ownerNr}-email`}
              name={`owner_${ownerNr}_email`}
              component={newThemeInput}
              type="email"
              label={'Email*'}
              placeholder="Email*"
              normalize={lowerCase}
            />
          </Stack>
        </>
      )}

      <SsnInput change={change} ownerIdx={ownerNr.toString()} autoFocus={ownerNr === 1} />

      <Stack
        flow="column"
        templateColumns="2fr 1fr"
        className="gap-2 w-full max-w-[368px]"
        gapVariant="input-to-input"
        alignItems="start"
      >
        <Field
          data-testid={`owner-${ownerNr}-title`}
          name={`owner_${ownerNr}_title`}
          component={newThemeInput}
          type="text"
          normalize={words}
          label="Position in Company*"
        />

        <Field
          name={`owner_${ownerNr}_ownership`}
          data-testid={`owner-${ownerNr}-ownership`}
          component={newThemeInput}
          valueAsNumber
          type="tel"
          disabled={false}
          normalize={(value: string) => parseFloat((value ?? '').toString().replace(/[^0-9]/g, '')) ?? undefined}
          parse={(value: string) => parseFloat((value ?? '').toString().replace(/[^0-9]/g, '')) ?? 0}
          format={formatOwnershipPercentage}
          labelText="% Owned*"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Stack>

      <Stack
        flow="column"
        templateColumns="1fr 1fr 1fr"
        className="gap-2 w-full max-w-[368px]"
        gapVariant="input-to-input"
        alignItems="start"
      >
        <Field
          valueAsNumber
          component={muiCustomSelect}
          name={`owner_${ownerNr}_dob_month`}
          data-testid={`owner_${ownerNr}_dob_month`}
          label="Month*"
          selectValueProps={{ placeholder: 'Month*' }}
          options={[
            { value: 1, text: 'January' },
            { value: 2, text: 'February' },
            { value: 3, text: 'March' },
            { value: 4, text: 'April' },
            { value: 5, text: 'May' },
            { value: 6, text: 'June' },
            { value: 7, text: 'July' },
            { value: 8, text: 'August' },
            { value: 9, text: 'September' },
            { value: 10, text: 'October' },
            { value: 11, text: 'November' },
            { value: 12, text: 'December' },
          ]}
        />
        <Field
          valueAsNumber
          component={muiCustomSelect}
          name={`owner_${ownerNr}_dob_day`}
          data-testid={`owner_${ownerNr}_dob_day`}
          label="Day*"
          selectValueProps={{ placeholder: 'Day*' }}
          options={[
            { value: 1, text: '1' },
            { value: 2, text: '2' },
            { value: 3, text: '3' },
            { value: 4, text: '4' },
            { value: 5, text: '5' },
            { value: 6, text: '6' },
            { value: 7, text: '7' },
            { value: 8, text: '8' },
            { value: 9, text: '9' },
            { value: 10, text: '10' },
            { value: 11, text: '11' },
            { value: 12, text: '12' },
            { value: 13, text: '13' },
            { value: 14, text: '14' },
            { value: 15, text: '15' },
            { value: 16, text: '16' },
            { value: 17, text: '17' },
            { value: 18, text: '18' },
            { value: 19, text: '19' },
            { value: 20, text: '20' },
            { value: 21, text: '21' },
            { value: 22, text: '22' },
            { value: 23, text: '23' },
            { value: 24, text: '24' },
            { value: 25, text: '25' },
            { value: 26, text: '26' },
            { value: 27, text: '27' },
            { value: 28, text: '28' },
            { value: 29, text: '29' },
            { value: 30, text: '30' },
            { value: 31, text: '31' },
          ]}
        />

        <Field
          valueAsNumber
          component={muiCustomSelect}
          name={`owner_${ownerNr}_dob_year`}
          data-testid={`owner_${ownerNr}_dob_year`}
          label="Year*"
          selectValueProps={{ placeholder: 'Year*' }}
          options={[
            { value: 2008, text: '2008' },
            { value: 2007, text: '2007' },
            { value: 2006, text: '2006' },
            { value: 2005, text: '2005' },
            { value: 2004, text: '2004' },
            { value: 2003, text: '2003' },
            { value: 2002, text: '2002' },
            { value: 2001, text: '2001' },
            { value: 2000, text: '2000' },
            { value: 1999, text: '1999' },
            { value: 1998, text: '1998' },
            { value: 1997, text: '1997' },
            { value: 1996, text: '1996' },
            { value: 1995, text: '1995' },
            { value: 1994, text: '1994' },
            { value: 1993, text: '1993' },
            { value: 1992, text: '1992' },
            { value: 1991, text: '1991' },
            { value: 1990, text: '1990' },
            { value: 1989, text: '1989' },
            { value: 1988, text: '1988' },
            { value: 1987, text: '1987' },
            { value: 1986, text: '1986' },
            { value: 1985, text: '1985' },
            { value: 1984, text: '1984' },
            { value: 1983, text: '1983' },
            { value: 1982, text: '1982' },
            { value: 1981, text: '1981' },
            { value: 1980, text: '1980' },
            { value: 1979, text: '1979' },
            { value: 1978, text: '1978' },
            { value: 1977, text: '1977' },
            { value: 1976, text: '1976' },
            { value: 1975, text: '1975' },
            { value: 1974, text: '1974' },
            { value: 1973, text: '1973' },
            { value: 1972, text: '1972' },
            { value: 1971, text: '1971' },
            { value: 1970, text: '1970' },
            { value: 1969, text: '1969' },
            { value: 1968, text: '1968' },
            { value: 1967, text: '1967' },
            { value: 1966, text: '1966' },
            { value: 1965, text: '1965' },
            { value: 1964, text: '1964' },
            { value: 1963, text: '1963' },
            { value: 1962, text: '1962' },
            { value: 1961, text: '1961' },
            { value: 1960, text: '1960' },
            { value: 1959, text: '1959' },
            { value: 1958, text: '1958' },
            { value: 1957, text: '1957' },
            { value: 1956, text: '1956' },
            { value: 1955, text: '1955' },
            { value: 1954, text: '1954' },
            { value: 1953, text: '1953' },
            { value: 1952, text: '1952' },
            { value: 1951, text: '1951' },
            { value: 1950, text: '1950' },
            { value: 1949, text: '1949' },
            { value: 1948, text: '1948' },
            { value: 1947, text: '1947' },
            { value: 1946, text: '1946' },
            { value: 1945, text: '1945' },
            { value: 1944, text: '1944' },
            { value: 1943, text: '1943' },
            { value: 1942, text: '1942' },
            { value: 1941, text: '1941' },
            { value: 1940, text: '1940' },
            { value: 1939, text: '1939' },
            { value: 1938, text: '1938' },
            { value: 1937, text: '1937' },
            { value: 1936, text: '1936' },
            { value: 1935, text: '1935' },
            { value: 1934, text: '1934' },
            { value: 1933, text: '1933' },
            { value: 1932, text: '1932' },
            { value: 1931, text: '1931' },
            { value: 1930, text: '1930' },
            { value: 1929, text: '1929' },
            { value: 1928, text: '1928' },
            { value: 1927, text: '1927' },
            { value: 1926, text: '1926' },
            { value: 1925, text: '1925' },
            { value: 1924, text: '1924' },
            { value: 1923, text: '1923' },
            { value: 1922, text: '1922' },
            { value: 1921, text: '1921' },
            { value: 1920, text: '1920' },
            { value: 1919, text: '1919' },
            { value: 1918, text: '1918' },
            { value: 1917, text: '1917' },
            { value: 1916, text: '1916' },
            { value: 1915, text: '1915' },
            { value: 1914, text: '1914' },
            { value: 1913, text: '1913' },
            { value: 1912, text: '1912' },
            { value: 1911, text: '1911' },
            { value: 1910, text: '1910' },
            { value: 1909, text: '1909' },
            { value: 1908, text: '1908' },
            { value: 1907, text: '1907' },
            { value: 1906, text: '1906' },
            { value: 1905, text: '1905' },
            { value: 1904, text: '1904' },
            { value: 1903, text: '1903' },
            { value: 1902, text: '1902' },
            { value: 1901, text: '1901' },
            { value: 1900, text: '1900' },
          ]}
        />
      </Stack>

      <Address
        address={{
          street,
          suite,
          city,
          state,
          zip,
        }}
        change={change}
        ownerIdx={ownerNr}
        showDefaultManualAddress={false}
        autoFocus={false}
      />
    </Stack>
  );
};

const OwnerFieldsWrapper1 = reduxForm<OwnerFieldsProps, any>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_BUSINESS_OWNERS].zodSchema),
})(OwnerFieldsWrapper2);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState, ownProps: Omit<OwnerFieldsProps, 'change'>) => {
  return {
    street: appSelector(state, `owner_${ownProps.ownerNr}_street`),
    suite: appSelector(state, `owner_${ownProps.ownerNr}_suite`),
    city: appSelector(state, `owner_${ownProps.ownerNr}_city`),
    state: appSelector(state, `owner_${ownProps.ownerNr}_state`),
    zip: appSelector(state, `owner_${ownProps.ownerNr}_zip`),
  };
};

export const OwnerFields = connect(mapStateToProps, { change })(OwnerFieldsWrapper1);
