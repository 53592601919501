import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Spinner } from '@components/Spinner/Spinner';
import { change, getFormValues } from 'redux-form';
import { useAuth0 } from '@auth0/auth0-react';
import { axiosInstance } from '../../../api/axiosInstance';
import { auth0AuthroizationParams } from '../../../App';
import { jwtDecode } from 'jwt-decode';
import { setUser } from '@store/actions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { ApplyWizardSteps, STEP_VERIFY_LOGIN } from '@components/ApplyWizard/ApplyWizardSteps';

const Auth0Callback = ({ allValues }) => {
  const history = useHistory();
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { steps, setCurrentPath, changeStepAfterLogin } = useApplyWizardContext();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    (async () => {
      const { redirectTo } = location.state || {};

      const token = await getAccessTokenSilently({ authorizationParams: auth0AuthroizationParams });
      const idToken = await getIdTokenClaims();
      const decodedToken = jwtDecode(token);
      console.log('decodedToken2', decodedToken);
      console.log('idToken2', idToken);
      const data = {
        user: {
          loggedIn: isAuthenticated,
          token,
          image: idToken?.picture,
          email: idToken.email,
          name: idToken?.nickname,
          id: decodedToken?.['https://tento.co/user_id'],
          expires: decodedToken && decodedToken.exp ? decodedToken.exp * 1000 : undefined,
        },
      };

      if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        dispatch(setUser(data));
        dispatch(change('application', 'owner_1_email', idToken.email));
      }

      console.log('CALLBACK AUTH0');
      if (redirectTo) {
        if (steps.some((step) => step.path === redirectTo)) {
          setCurrentPath(redirectTo);
        } else {
          history.push(redirectTo);
        }
      } else {
        if (allValues.business_id) {
          changeStepAfterLogin();
        } else if (
          allValues.business_name &&
          allValues.owner_1_email &&
          allValues.owner_1_mobile &&
          allValues.owner_1_first &&
          allValues.owner_1_last &&
          allValues.loan_amount &&
          allValues.loan_terms &&
          allValues.loan_type
        ) {
          // No deal id but data present so it needs to be saved
          history.push(`/app/create`);
        } else {
          // No deal id and no deal data - fresh login (from homepage likely)
          history.push(`/app/select`);
        }
      }
    })();
  }, [isAuthenticated]);
  return <Spinner />;
};

const mapStateToProps = (state) => {
  return {
    allValues: getFormValues('application')(state) || {},
  };
};

export const Auth0CallbackContainer = connect(mapStateToProps)(Auth0Callback);

export default Auth0CallbackContainer;
