import 'Apply/Amount/Amount.scss';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { newThemeInput, rangeInput } from '_fields/inputs';
import { required } from '_fields/validation';
import { currency } from '_fields/normalizers';
import { parseCurrency } from '_fields/parsers';
import TermSelector from '@components/_shared/TermSelector';
import { PaymentSelected } from '@components/_shared/Payment';
import { calcEstimatedPayment } from '@utils/paymentCalculations';
import { setTemp, clearTemp, AVAILABLE_TERM_OPTIONS } from '@store/actions';
import { ModalManager } from 'react-dynamic-modal';
import { ConfirmModal } from '@components/_shared/Modals/Modals';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { Helmet } from 'react-helmet';
import ContinueButton from '@components/_shared/Continue/Button';
import { Stack, Text } from 'shared-components';

const EnhancedTerms = ({
  offer,
  history,
  loanAmount,
  change,
  wcFloor,
  setTemp,
  terms,
  loanType,
  theme,
  payment,
  interest,
  confirmTerms,
  images,
  config,
}) => {
  const rhfForm = useForm();
  const loanTypeSafe = Array.isArray(loanType)
    ? loanType.length > 0
      ? loanType[0].toString().toUpperCase()
      : 'WC'
    : loanType?.toString().toUpperCase() ?? 'WC';
  const { min, max } = config[loanTypeSafe?.toUpperCase()];
  /**
   * WC - initially cap amount at 25K to display lower payment/interest values, allow setting to max
   */
  const setLoanAmount = () => {
    if (offer.loanType === 'WC') {
      if (!wcFloor) {
        // if requested loanAmount > 25K, drop it down to show lower payment/interest
        loanAmount = loanAmount > 25000 ? 25000 : loanAmount;
        // set this value to avoid dropping loanAmount for WC back down to 25k upon returning to page
        setTemp({
          wcApprovedAmountFloored: true,
        });
      }
    }

    const max = offer.amount;
    const amt = loanAmount < max ? loanAmount : max;

    // change('loan_amount', Number(amt));
  };

  // ensure that term selection is a valid option
  const setTermLength = () => {
    const termOptions = Object.keys(offer.terms);
    if (termOptions.indexOf(terms) === -1) {
      const validTermLength = termOptions.reduce((prev, curr) =>
        Math.abs(curr - terms) < Math.abs(prev - terms) ? curr : prev
      );
      change('loan_terms', String(validTermLength));
    }
  };

  const updatePayment = () => {
    const payment = calcEstimatedPayment(loanAmount, terms, offer);

    if (loanTypeSafe === 'EQ') {
      change('loan_payment', payment);
      change('loan_num_payments', terms);
    } else if (loanTypeSafe === 'WC') {
      change('loan_payment', payment.payment);
      change('loan_interest', payment.interest);
      change('loan_factor', payment.factor);
      change('loan_num_payments', payment.numPayments);
    }
  };

  const syncValues = (newAmount) => {
    updatePayment();
  };

  useEffect(() => {
    if (offer) {
      setLoanAmount();
      setTermLength();
      // update calulated payment, setTimeout ensures setLoanAmount redux change is completed
      window.setTimeout(() => {
        updatePayment();
      }, 10);
    } else {
      history.replace('/app/bank/connect');
    }
  }, [offer]);

  const showConfirmModal = () => {
    ModalManager.open(
      <ConfirmModal
        onRequestClose={() => false}
        theme={theme}
        amount={loanAmount}
        terms={terms}
        payment={payment}
        interest={interest}
        offer={offer}
        confirmTerms={confirmTerms}
        media={images}
      />
    );
  };
  const termOptions = Object.keys(offer.terms);

  return (
    <div className="app-section slide">
      <Helmet>
        <title>Terms - Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText="You've Been approved!" />

        <Stack gapPx="20px">
          <Stack gapVariant="input-to-input">
            <div className="flex items-center justify-center">
              <Text variant="headline-sm">Get Funding Up To: $250k</Text>
            </div>
            <div className="flex items-center justify-center">
              <Text variant="body-general">Keep selected amount or increase your funding.</Text>
            </div>
          </Stack>
          <Form {...rhfForm}>
            <Stack gapVariant="content-to-submit-button">
              <Stack gapPx="30px" justifyItems="center" templateColumns="1fr" fullWidth>
                <Field
                  name="loan_amount"
                  component={newThemeInput}
                  className="amount"
                  type="tel"
                  placeholder="Enter Amount"
                  validate={[required]}
                  formatAsNumber
                  parse={parseCurrency}
                  onChange={(event, newValue, previousValue) => {
                    window.setTimeout(() => syncValues(newValue), 0);
                  }}
                />
                <Stack gapVariant="input-to-input" justifyItems="center" alignItems="center" fullWidth>
                  <div className="h-[58px] mt-[10px] max-w-[368px] w-full">
                    <Field
                      name="loan_amount"
                      component={rangeInput}
                      min={min}
                      max={max}
                      data-testid="loan_amount_slider"
                    />
                  </div>
                </Stack>
                <div className="flex items-center justify-center">
                  <Text variant="headline-sm">Select Term</Text>
                </div>
                <TermSelector
                  // TODO: replace AVAILABLE_TERM_OPTIONS with termOptions but it only supports WC and EQ right now.
                  termOptions={AVAILABLE_TERM_OPTIONS /* termOptions */}
                  loanAmount={loanAmount}
                  loanType={loanTypeSafe}
                  rate={offer.rate}
                  updatePayment={updatePayment}
                />

                <PaymentSelected payment={payment} interest={interest} offer={offer} />
              </Stack>

              <ContinueButton data-testid="terms_continue" onClick={showConfirmModal} btnText="Next Steps" />
            </Stack>

            <p className="disclaimer">
              *The requested financing is contingent upon final credit approval by tento X Inc., or its successors and
              assigns, in their sole and exclusive judgment and discretion.
            </p>
          </Form>
        </Stack>
      </Stack>
    </div>
  );
};

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    loanAmount: selector(state, 'loan_amount'),
    terms: selector(state, 'loan_terms'),
    payment: selector(state, 'loan_payment'),
    interest: selector(state, 'loan_interest'),
    loanType: selector(state, 'loan_type'),
    config: state.config,
    theme: state.theme,
    offer: state.temp.offer,
    wcFloor: state.temp.wcApprovedAmountFloored,
    images: state.brand?.data?.media,
  };
};

const WrappedEnhancedTerms = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(EnhancedTerms);

const Terms = connect(mapStateToProps, { setTemp, clearTemp })(WrappedEnhancedTerms);

export default Terms;
