import { normFedTaxId } from '@components/_fields/normalizers';
import { AVAILABLE_TERM_OPTIONS } from '@store/actions';
import { RootState } from '@store/reducers';
import { calculateBusinessYears } from '@utils/calculateBusinessYears';
import { changePriceToNumber } from '@utils/changePriceToNumber';
import { normalizePhoneNumber } from '@utils/normalizePhoneNumber';
import { estPaymentByApr } from '@utils/paymentCalculations';
import { AnyAction, Dispatch } from 'redux';
import { FormAction, change, reset } from 'redux-form';
import { BusinessFormattedResponse, ContactFormattedResponse, EntireDealFormattedResponse } from 'src/js/types';

export const prefillContactInReduxByIndex = ({
  index,
  sortedContacts,
  dispatch,
  user,
}: {
  index: number;
  sortedContacts: ContactFormattedResponse[];
  dispatch: Dispatch<AnyAction>;
  user: RootState['user'];
}) => {
  if (
    !(
      sortedContacts.length > index &&
      sortedContacts[index].ownerPercentage &&
      sortedContacts[index]!.ownerPercentage! > 0
    )
  ) {
    return;
  }
  if (sortedContacts.length > index && sortedContacts[index].id) {
    dispatch(change('application', `owner_${index + 1}_id`, sortedContacts[index].id));
  }
  if (sortedContacts.length > index && sortedContacts[index].firstName) {
    dispatch(change('application', `owner_${index + 1}_first`, sortedContacts[index].firstName));
  }
  if (sortedContacts.length > index && sortedContacts[index].lastName) {
    dispatch(change('application', `owner_${index + 1}_last`, sortedContacts[index].lastName));
  }
  if (sortedContacts.length > index && sortedContacts[index].phoneMobile) {
    dispatch(
      change('application', `owner_${index + 1}_mobile`, normalizePhoneNumber(sortedContacts[index].phoneMobile))
    );
  }
  if (sortedContacts.length > index && sortedContacts[index].email) {
    dispatch(change('application', `owner_${index + 1}_email`, sortedContacts[index].email));
    dispatch(change('application', 'consent', true));
  } else if (user && user.email && index === 0 && sortedContacts[index].isMainContact) {
    dispatch(change('application', `owner_${index + 1}_email`, user.email));
    dispatch(change('application', 'consent', true));
  }
  if (sortedContacts.length > index && sortedContacts[index].title) {
    dispatch(change('application', `owner_${index + 1}_title`, sortedContacts[index].title));
  }
  if (sortedContacts.length > index && sortedContacts[index].ownerPercentage) {
    dispatch(
      change('application', `owner_${index + 1}_ownership`, Number(sortedContacts[index].ownerPercentage?.toString()))
    );
  }
  if (sortedContacts.length > index && sortedContacts[index].dob) {
    dispatch(change('application', `owner_${index + 1}_dob`, sortedContacts[index].dob));
    const date = new Date(sortedContacts[index]?.dob as string);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Returns the month (0-11), so we add 1
    const year = date.getUTCFullYear();
    dispatch(change('application', `owner_${index + 1}_dob_month`, month));
    dispatch(change('application', `owner_${index + 1}_dob_year`, year));
    dispatch(change('application', `owner_${index + 1}_dob_day`, day));
  }
  if (sortedContacts.length > index && sortedContacts[index].ssn) {
    dispatch(change('application', `owner_${index + 1}_ssn`, sortedContacts[index].ssn));
  }
  if (sortedContacts.length > index && sortedContacts[index].address?.address1) {
    dispatch(change('application', `owner_${index + 1}_street`, sortedContacts[index].address?.address1));
  }
  if (sortedContacts.length > index && sortedContacts[index].address?.city) {
    dispatch(change('application', `owner_${index + 1}_city`, sortedContacts[index].address?.city));
  }
  if (sortedContacts.length > index && sortedContacts[index].address?.postalCode) {
    dispatch(change('application', `owner_${index + 1}_zip`, sortedContacts[index].address?.postalCode));
  }
  if (sortedContacts.length > index && sortedContacts[index].address?.state) {
    dispatch(change('application', `owner_${index + 1}_state`, sortedContacts[index].address?.state));
  }
  if (sortedContacts.length > index && sortedContacts[index].address?.address2) {
    dispatch(change('application', `owner_${index + 1}_suite`, sortedContacts[index].address?.address2));
  }
};

export const prefillReduxStateFromDealResponse = ({
  dispatch,
  config,
  user,
  deal,
}: {
  dispatch: Dispatch<AnyAction>;
  config: RootState['config'];
  user: RootState['user'];
  deal: EntireDealFormattedResponse;
}) => {
  dispatch(reset('application'));
  const sortedContacts =
    deal?.business?.contacts?.length ?? 0 > 0
      ? [...(deal?.business?.contacts ?? [])].sort((a, b) => {
          // Convert isMainContact to a number for comparison
          // true => 1, false/undefined/null => 0
          const valA = a.isMainContact ? 1 : 0;
          const valB = b.isMainContact ? 1 : 0;

          // Sort so that true values come first
          return valB - valA;
        })
      : [];
  if (deal?.loanRequest?.amount) {
    dispatch(change('application', 'loan_amount', changePriceToNumber(deal?.loanRequest?.amount)));
  }
  if (deal?.loanRequest?.stage) {
    dispatch(change('application', 'loan_stage', deal?.loanRequest?.stage));
  }
  if (deal?.loanRequest?.termLength) {
    dispatch(
      change(
        'application',
        'loan_terms',
        AVAILABLE_TERM_OPTIONS.some((nr) => nr === Number(deal?.loanRequest?.termLength))
          ? Number(deal?.loanRequest?.termLength)
          : 60
      )
    );
  }
  if (deal?.loanRequest?.type) {
    dispatch(change('application', 'loan_type', [deal?.loanRequest?.type.toUpperCase()]));
  } else if (deal.product) {
    dispatch(change('application', 'loan_type', [deal.product.toUpperCase()]));
  } else {
    dispatch(change('application', 'loan_type', ['WC'])); // Assume some default if for some weird reason it is missing.
  }

  if (deal?.loanRequest?.amount && deal?.loanRequest?.termLength && deal?.loanRequest?.type) {
    const paymentAmount = estPaymentByApr(
      deal?.loanRequest?.amount,
      deal?.loanRequest?.termLength,
      (config as any)[(deal?.loanRequest?.type ?? deal.product ?? 'WC').toString().toUpperCase()].rate
    );
    console.log('paymentAmount', paymentAmount);
    dispatch(change('application', 'loan_payment', paymentAmount));
  }
  if (deal?.loanRequest?.equipmentDesc) {
    dispatch(change('application', 'equipment_description', deal?.loanRequest?.equipmentDesc));
  }

  if (deal?.business?.id) {
    dispatch(change('application', 'business_id', deal?.business?.id));
  }
  if (deal?.business?.name) {
    dispatch(change('application', 'business_name', deal?.business?.name));
  }

  if (deal?.business?.annualRevenue) {
    dispatch(change('application', 'business_revenue', deal?.business?.annualRevenue));
  }
  if (deal?.business?.businessYears) {
    dispatch(change('application', 'business_years', Number(deal?.business?.businessYears)));
  } else if (deal?.business?.businessInceptionDate) {
    dispatch(change('application', 'business_years', calculateBusinessYears(deal?.business?.businessInceptionDate)));
  }
  if (deal?.business?.phone) {
    dispatch(change('application', 'business_phone', normalizePhoneNumber(deal?.business?.phone)));
  }
  if (deal?.business?.address?.address1) {
    dispatch(change('application', 'business_street', deal?.business?.address.address1));
  }
  if (deal?.business?.address?.city) {
    dispatch(change('application', 'business_city', deal?.business?.address.city));
  }
  if (deal?.business?.address?.postalCode) {
    dispatch(change('application', 'business_zip', deal?.business?.address.postalCode));
  }
  if (deal?.business?.address?.state) {
    dispatch(change('application', 'business_state', deal?.business?.address.state));
  }
  if (deal?.business?.address?.address2) {
    dispatch(change('application', 'business_suite', deal?.business?.address.address2));
  }

  if (deal?.business?.naicsCode) {
    dispatch(change('application', 'business_naics', Number(deal?.business?.naicsCode)));
    dispatch(change('application', 'naics_keyword', deal?.business?.naicsCode));
  }
  if (deal?.business?.entityType) {
    dispatch(change('application', 'business_structure', deal?.business?.entityType));
  }
  if (deal?.business?.taxIdNumber) {
    dispatch(change('application', 'business_ein', normFedTaxId(deal?.business?.taxIdNumber)));
  }
  if (deal?.business?.website) {
    dispatch(change('application', 'business_website', deal?.business?.website));
  }
  if (deal?.loanRequest?.bankStatements) {
    dispatch(
      change(
        'application',
        'have_statements',
        deal?.loanRequest?.bankStatements === 'plaid' || deal?.loanRequest?.bankStatements === 'upload' ? true : false
      )
    );
    dispatch(change('application', 'type_of_statements', deal?.loanRequest?.bankStatements));
  } else {
    dispatch(change('application', 'have_statements', false));
  }

  sortedContacts.forEach((contact, index) => {
    prefillContactInReduxByIndex({ index, sortedContacts, dispatch, user });
  });
};
