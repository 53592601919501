import './Modals.scss';
import React from 'react';
import { Modal, ModalManager, Effect } from 'react-dynamic-modal';
import { currency } from '_fields/normalizers';
import { PaymentSelected } from '@components/_shared/Payment';
import { Outro } from '../../Apply/Done/Fragments';
import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@components/Stack/Stack';
import { ELEMENTS_GAP } from '@utils/spacing';
import ContinueButton from '@components/_shared/Continue/Button';
import { Button } from 'shared-components';
import { Text } from 'shared-components';

export const BasicModal = (props) => {
  const { title, body, effect, onRequestClose } = props;
  return (
    <ApplyTheme theme={props.theme}>
      <Modal style={{ content: { width: '280px' } }} onRequestClose={onRequestClose} effect={effect}>
        <Text variant="headline-lg">{title}</Text>
        <div>{body}</div>
      </Modal>
    </ApplyTheme>
  );
};

export const AccountBody = (props) => {
  return (
    <ul className="accountList">
      {props.accounts.map((account) => (
        <li key={account.id} onClick={() => props.itemClick(account.id)}>
          {account.name} / {account.mask}
        </li>
      ))}
    </ul>
  );
};

export const DoneModal = (props) => {
  const { logout } = useAuth0();
  return (
    <ApplyTheme theme={props.theme}>
      <Modal
        onRequestClose={() => false}
        effect={Effect.Fall}
        style={{
          overlay: {
            top: '64px',
            zIndex: '99',
            backgroundColor: '#FFFFFF',
            overflow: 'auto',
          },
          content: {
            boxShadow: 'none',
            background: 'transparent',
            border: 'none',
            margin: '0 auto',
            marginTop: '35px',
            width: '100%',
          },
        }}
      >
        <div className={`done-modal inverse ${props.theme?.name}`}>
          <Stack gap={ELEMENTS_GAP}>
            <Text variant="headline-lg">Congratulations !!</Text>
            <h5 data-testid="loading-title">We are checking a few things. Hold tight!</h5>
            <hr className="m-auto divider" />
            <p className="darkText bold">We have received your equipment financing application</p>
            <p>{props.message}</p>
            <Outro {...props} />
            <Button
              variant="primary-green"
              data-testid="done-modal-logout"
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >
              Logout
            </Button>
          </Stack>
        </div>
      </Modal>
    </ApplyTheme>
  );
};

export const ProcessingModal = (props) => {
  const { title, msg, images } = props;
  const message = msg ? msg : "This shouldn't take long. We appreciate your patience.";

  return (
    <ApplyTheme theme={props.theme}>
      <Modal
        effect={Effect.Fall}
        onRequestClose={props.onRequestClose}
        style={{
          overlay: { backgroundColor: '#FFFFFF' },
          content: {
            boxShadow: 'none',
            background: 'transparent',
            border: 'none',
            margin: '131px auto',
            width: '100%',
            overflow: 'none',
          },
        }}
      >
        <div className="processing-modal">
          <Stack gap={ELEMENTS_GAP}>
            <img src={images.icon_url} alt="icon" className="mx-auto icon" />

            <h1 className="my-0 title">{title}</h1>
            <h5>{message}</h5>
          </Stack>

          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Modal>
    </ApplyTheme>
  );
};

export const ConfirmModal = (props) => {
  return (
    <ApplyTheme theme={props.theme}>
      <Modal
        effect={Effect.Fall}
        onRequestClose={props.onRequestClose}
        style={{
          overlay: { backgroundColor: 'white' },
          content: {
            boxShadow: 'none',
            background: 'transparent',
            border: 'none',
            margin: '35px auto',
            width: '100%',
          },
        }}
      >
        <div className="confirm-modal">
          <Stack gap={ELEMENTS_GAP}>
            {props.media?.icon_white_url && <img src={props.media?.icon_white_url} alt="icon" className="icon-white" />}

            <h1 className="title">
              You're moments
              <br />
              away from funding!
            </h1>

            <h4 className="blue tiny">Please confirm your loan selection:</h4>

            <div className="loanOverview group">
              <div className="loanAmountBox">
                <p>Loan amount</p>
                <p className="big">{currency(props.amount)}</p>
              </div>
              <div className="termLengthBox">
                <p>Term length</p>
                <p className="big">{props.terms} months</p>
              </div>
            </div>
            <PaymentSelected payment={props.payment} interest={props.interest} offer={props.offer} />

            <div className="wrap">
              <ContinueButton data-testid="terms_modal_continue" onClick={props.confirmTerms} />
            </div>
            <div className="closeConfirmModal" onClick={ModalManager.close}>{`< Go back and edit`}</div>
          </Stack>
        </div>
      </Modal>
    </ApplyTheme>
  );
};

const ApplyTheme = ({ children, theme }) => (
  <div
    style={{
      '--default-primary-color': theme?.primary,
      '--default-primary-rgba': theme?.primary_rgba,
      '--default-hover-color': theme?.hover_color,
      '--logoScalePercent': theme?.logo?.scale_percent,
      '--logoPosition': theme?.logo?.position,
      '--t1': theme?.font_sizes?.t1,
      '--t2': theme?.font_sizes?.t2,
      '--t2LineH': theme?.font_sizes?.t2LineH,
      '--t3': theme?.font_sizes?.t3,
      '--t4': theme?.font_sizes?.t4,
      '--t5': theme?.font_sizes?.t5,
      '--t5LineH': theme?.font_sizes?.t5LineH,
      '--s0': theme?.font_sizes?.s0,
      '--s1': theme?.font_sizes?.s1,
      '--s2': theme?.font_sizes?.s2,
      '--s3': theme?.font_sizes?.s3,
      '--s4': theme?.font_sizes?.s4,
      '--inputPlaceholderSize': theme?.font_sizes?.inputPlaceholderSize,
      '--inputErrorSize': theme?.font_sizes?.inputErrorSize,
      '--mainFont': theme?.font_family,
    }}
  >
    {children}
  </div>
);
