import './Amount.scss';
import React from 'react';
import { formValueSelector, reduxForm } from 'redux-form';
import { newThemeInput, rangeInput } from '_fields/inputs';
import { currency } from '_fields/normalizers';
import { parseCurrency } from '_fields/parsers';
import { Field } from 'redux-form';
import TermSelector from '@components/_shared/TermSelector';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '../../_shared/FormPageTopPart/FormPageTopPart';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { Helmet } from 'react-helmet';
import { Loader, Stack, Text } from 'shared-components';
import { connect } from 'react-redux';
import { ApplyWizardSteps, STEP_AMOUNT } from '@components/ApplyWizard/ApplyWizardSteps';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { AVAILABLE_TERM_OPTIONS } from '@store/actions';

let Amount = (props) => {
  const rhfForm = useForm();

  // prevent errors while waiting for config to load
  if (props.config.customVendor && !props.config.loaded) {
    return (
      <div className="flex flex-1 items-center">
        <Loader />
      </div>
    );
  }

  const { min, max, termOptions, rate } =
    props.config[props.loanType?.toUpperCase() === 'WC' ? 'WC' : props.loanType?.toUpperCase() === 'EQ' ? 'EQ' : 'WC'];

  return (
    <div className="amountSection">
      <Helmet>
        <title>Select Amount - Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText={'Tell Us How We Can Help'} />
        <Form {...rhfForm}>
          <form onSubmit={props.handleSubmit}>
            <Stack gapVariant="content-to-submit-button">
              <Stack gapPx="30px">
                {props.showBanner && (
                  <p className="noticeBanner">
                    We noticed you are accessing this from outside the US. Please note we do not currently provide
                    services to non US Applicants
                  </p>
                )}
                <div className="flex items-center justify-center">
                  <Text variant="headline-sm">Choose Desired Amount</Text>
                </div>
                <Field
                  name="loan_amount"
                  label="Amount (USD)"
                  component={newThemeInput}
                  className="amount"
                  type="tel"
                  placeholder="Enter Amount"
                  formatAsNumber
                  parse={parseCurrency}
                  data-testid="loan-amount"
                />

                <Stack gapVariant="input-to-input" justifyItems="center" alignItems="center" fullWidth>
                  <div className="h-[58px] mt-[10px] max-w-[368px] w-full">
                    <Field
                      name="loan_amount"
                      component={rangeInput}
                      min={min}
                      max={max}
                      data-testid="loan_amount_slider"
                    />
                  </div>
                </Stack>

                <div className="flex items-center justify-center">
                  <Text variant="headline-sm">Select Term</Text>
                </div>
                <TermSelector
                  // TODO: replace AVAILABLE_TERM_OPTIONS with termOptions but it only supports WC and EQ right now.
                  termOptions={AVAILABLE_TERM_OPTIONS /* termOptions */}
                  loanAmount={props.loanAmount}
                  loanType={props.loanType}
                  rate={rate}
                  updatePayment={props.updatePayment}
                  terms={props.loan_terms}
                  config={props.config}
                  withMonthlyPayment
                />
              </Stack>
              <ContinueButton invalid={props.invalid} saving={props.saving} data-testid="amount_continue" />
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

Amount = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_AMOUNT].zodSchema),
})(Amount);

const selector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    loan_terms: selector(state, 'loan_terms'),
    loanPayment: selector(state, 'loan_payment'),
    config: state.config,
  };
};

Amount = connect(mapStateToProps)(Amount);

export default Amount;
