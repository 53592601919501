import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import { getNaicsCodes } from '../../../api/getNaicsCodes';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { Loader, Stack } from 'shared-components';
import { muiCustomSelect, selectDynamicInput } from '@components/_fields/inputs';

// const NaicsFieldInternal = ({ naics, change }) => {
//   const isDefaultValueNumberFromBackend = naics && typeof naics !== 'object';
//   const [isNaicsPreloaded, setIsNaicsPreloaded] = useState(isDefaultValueNumberFromBackend ? false : true);
//   const [{ data: naicsSearchResponse, loading: naicsSearchLoading, error: naicsSearchError }, triggerNaicsSearch] =
//     getNaicsCodes();
//   console.log('naics', naics);

//   const naicsSearch = async (keyword) => {
//     if (process.env.NODE_ENV === 'test') {
//       return [{ value: '811198', text: '811198' }];
//     }
//     const naicsQueried = await triggerNaicsSearch(keyword);
//     return naicsQueried.data?.map((naics) => {
//       return {
//         text: `(${naics.code}) ${naics.industry}`,
//         value: naics.code?.toString(),
//       };
//     });
//   };

//   useEffect(() => {
//     (async () => {
//       const preLoadNaicsSearch = async () => {
//         const naicsCodes = await naicsSearch(isDefaultValueNumberFromBackend ? naics : naics?.value);
//         const defaultNaics = naicsCodes.find((naicsCode) => naicsCode.value === naics);
//         console.log('defaultNaics', defaultNaics, naicsCodes, naics);
//         change('business_naics', defaultNaics ?? { value: naics, text: naics });
//         setIsNaicsPreloaded(true);
//       };
//       if (isDefaultValueNumberFromBackend) {
//         await preLoadNaicsSearch();
//       }
//     })();
//   }, []);

//   useEffect(() => {
//     if (process.env.NODE_ENV === 'test') {
//       // TODO: dirty hack for unit test, as select is bugged in Jest environment.
//       // TODO: not sure if needed after redesign (at this point tests werent updated yet)
//       change('business_naics', { value: '811198', text: '811198' });
//     } else {
//       if (isDefaultValueNumberFromBackend) {
//         change('business_naics', { value: naics, text: naics });
//       }
//     }
//   }, [naicsSearchResponse]);

//   return (
//     <>
//       {naics && !isNaicsPreloaded ? (
//         <Loader />
//       ) : (
//         <Field
//           name="business_naics"
//           component={selectDynamicInput}
//           queryOptions={naicsSearch}
//           placeholderButton={'Select Industry Code'}
//           placeholderInput="Search for Industry Code"
//           popOverContentProps={{
//             className: 'max-w-[368px]',
//           }}
//           buttonProps={{
//             className: '!max-w-[368px] w-full overflow-hidden col-span-full',
//           }}
//           commandEmptyProps={{
//             children: 'No results found. Please try again.',
//           }}
//           defaultValue={naics ? naics : undefined}
//           validate={[required]}
//           commandItemProps={{
//             onSelect: (item) => {
//               if (!item) {
//                 change('business_naics', '');
//                 change('naics_keyword', '');
//               }
//             },
//           }}
//         />
//       )}
//       {naicsSearchError && (
//         <Alert severity="error">
//           <AlertTitle>Failed search</AlertTitle>
//           <DataError msg="Unfortunately, naics search failed. Please try again." error={naicsSearchError} />
//         </Alert>
//       )}
//     </>
//   );
// };

// const selector = formValueSelector('application');
// const mapStateToProps = (state) => {
//   return {
//     naics: selector(state, 'business_naics'),
//     keyword: selector(state, 'naics_keyword'),
//   };
// };

// export const NaicsField = reduxForm({
//   form: 'application',
//   destroyOnUnmount: false,
//   forceUnregisterOnUnmount: true,
// })(connect(mapStateToProps)(NaicsFieldInternal));
const naicsToSelect = [
  { text: 'Business Support Services', value: 561400 },
  { text: 'Crop Harvesting', value: 115113 },
  { text: 'Specialty Trade Contractors', value: 238000 },
  { text: 'Electronic Shopping', value: 454111 },
  { text: 'Educational Services', value: 611000 },
  { text: 'All Other Nondepository Credit Intermediation', value: 522298 },
  { text: 'Offices of Physicians', value: 621111 },
  { text: 'Offices of Other Holding Companies', value: 551112 },
  { text: 'Full-Service Restaurants', value: 722511 },
  { text: 'Fabricated Metal Product Manufacturing', value: 332000 },
  { text: 'Support Activities for Oil and Gas', value: 213112 },
  { text: 'Professional, Scientific, and Technical Services', value: 541000 },
  { text: 'Administration of Education Programs', value: 923110 },
  { text: 'Other Activities Related to Real Estate', value: 531390 },
  { text: 'Fitness and Recreation Sports Centers', value: 713940 },
  { text: 'Retail Trade', value: 44 },
  { text: 'Telecommunications', value: 517000 },
  { text: 'General Freight Trucking, Local', value: 484110 },
  { text: 'Utilities', value: 221000 },
  { text: 'Hardware Merchant Wholesalers', value: 423710 },
  { text: 'Other', value: 81 },
].sort((a, b) => a.text.localeCompare(b.text));

export const NaicsField = () => (
  <Field
    name="business_naics"
    options={naicsToSelect}
    component={muiCustomSelect}
    label="Select Industry"
    className="w100 mui-input-custom mui-select industry-select naics-select"
    data-testid="business_naics"
    valueAsNumber
    selectValueProps={{ placeholder: 'Select Industry' }}
  />
);
