import React from 'react';
import { Field } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import { phone } from '_fields/normalizers';
import { required, phoneNumber } from '_fields/validation';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';

const Phone = (props) => {
  return (
    <Stack gapVariant="head-section-to-content">
      <Helmet>
        <title>Business Phone Number - Your Loan Application</title>
      </Helmet>
      <FormPageTopPart headingText="Whats is your business phone number?" />
      <Stack gapVariant="input-to-input" alignItems="center" justifyItems="center" templateColumns="1fr">
        <Field
          name="business_phone"
          component={newThemeInput}
          type="tel"
          formatAsPhone
          validate={[required, phoneNumber]}
          autoFocus
          labelText="Business Phone Number*"
          labelClassName="infoFormCustomLabel"
          placeholder="Enter phone number"
          data-testid="business_phone"
        />
      </Stack>
    </Stack>
  );
};

export default Phone;
