import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setUser } from '../../../redux/actions';
import { backendBaseUrl } from '../../../utils/platformBasedInfo';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { getLoaneeDeal } from '../../../api/getLoaneeDeal';
import { change } from 'redux-form';
import { postLoaneeOpportunity } from '../../../api/postLoaneeOpportunity';
import { prefillReduxStateFromDealResponse } from '@components/Apply/SelectApplication/prefillReduxStateFromDealResponse';
import { Helmet } from 'react-helmet';
import { currencyToDecimal } from '../../../utils/currencyToDecimal';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import { LoadingTento } from 'shared-components';
import { formValueSelector } from 'redux-form';

const CreateApplicationContainer = ({
  routes,
  config,
  finalInAppValues,
  reduxUserSaved,
  vendorId,
  partnerCustomFields,
  brandId,
  partnerId,
  loanType,
}) => {
  const dispatch = useDispatch();
  const [createdDealId, setCreatedDealId] = useState();
  const { changeStepAfterLogin } = useApplyWizardContext();
  const data = {
    business: {
      name: finalInAppValues.business_name,
      email: finalInAppValues.owner_1_email,
      phone: finalInAppValues.owner_1_mobile,
      contacts: [
        {
          isMainContact: true,
          firstName: finalInAppValues.owner_1_first,
          lastName: finalInAppValues.owner_1_last,
          email: finalInAppValues.owner_1_email,
          phoneMobile: finalInAppValues.owner_1_mobile,
        },
      ],
    },
    loanRequest: {
      amount: currencyToDecimal(finalInAppValues.loan_amount),
      termLength: finalInAppValues.loan_terms,
      termUnit: 'month', // Currently only month in the UI.
      equipmentDesc: finalInAppValues.equipment_description,
    },
    product: Array.isArray(loanType)
      ? loanType.length > 0
        ? loanType[0].toString().toUpperCase()
        : 'WC'
      : loanType?.toString().toUpperCase() ?? 'WC',
    metadata: {
      loanTypes: loanType,
    },
    partnerCustomFields,
    brandId,
    partnerId,
  };

  const [{ data: entireDealDataResponse, loading: entireDealLoading, error: entireDealError }, getEntireDeal] =
    getLoaneeDeal();
  const [
    { data: postOpportunityResponse, loading: postOpportunityLoading, error: postOpportunityError },
    postOpportunity,
  ] = postLoaneeOpportunity();

  useEffect(() => {
    postOpportunity({ data });
  }, []);

  useEffect(() => {
    if (
      postOpportunityResponse?.opportunityId &&
      postOpportunityResponse?.contactId &&
      postOpportunityResponse?.accountId
    ) {
      setCreatedDealId(postOpportunityResponse.opportunityId);
    }
  }, [postOpportunityResponse]);

  useEffect(() => {
    if (createdDealId === null || createdDealId === undefined) {
      return;
    }
    getEntireDeal({
      url: `${backendBaseUrl}/deals/loanee/${createdDealId}`,
    });
  }, [createdDealId]);

  useEffect(() => {
    if (entireDealDataResponse) {
      const accountId = entireDealDataResponse?.business?.id;
      const dealId = entireDealDataResponse?.id ?? entireDealDataResponse?.loanRequest?.id;
      const data = {
        user: {
          ...reduxUserSaved,
          accountId: entireDealDataResponse?.business?.id,
        },
      };
      prefillReduxStateFromDealResponse({
        dispatch,
        config,
        user: data.user,
        deal: entireDealDataResponse,
      });
      dispatch(setUser(data));
      dispatch(change('application', 'business_id', accountId));
      dispatch(change('application', 'loan_id', dealId));
      dispatch(
        setUser({
          user: {
            ...reduxUserSaved,
            id: postOpportunityResponse.contactId,
            accountId: postOpportunityResponse.accountId,
          },
        })
      );
      dispatch(change('application', 'owner_1_id', postOpportunityResponse.contactId));

      changeStepAfterLogin();
    }
  }, [entireDealDataResponse]);

  return (
    <div className="app-section slide">
      <Helmet>
        <title>Creating New Loan Application</title>
      </Helmet>
      <div className="slideWidth" style={{ marginTop: '16px' }}>
        {entireDealError && (
          <Alert severity="error">
            <AlertTitle>Error Loading Created Application</AlertTitle>
            <DataError error={entireDealError} />
          </Alert>
        )}
        {postOpportunityError && (
          <Alert severity="error">
            <AlertTitle>Error Creating Your Deal (#003)</AlertTitle>
            <DataError error={postOpportunityError} />
          </Alert>
        )}
        {(entireDealLoading || postOpportunityLoading) && !entireDealError && !postOpportunityError && (
          <div className="flex flex-1 items-center">
            <LoadingTento width={undefined} height={undefined} />
          </div>
        )}
      </div>
    </div>
  );
};

const appSelector = formValueSelector('application');

const mapStateToProps = (state) => {
  return {
    config: state.config,
    reduxUserSaved: state.user,
    finalInAppValues: state.form.application?.values,
    routes: state.brand?.data?.routes,
    partnerCustomFields: state.partnerCustomFields,
    vendorId: state.brand?.data?.vendor_id,
    brandId: state.brand?.data?.brand?.id,
    partnerId: state.brand?.data?.brand?.partner_id,
    loanType: appSelector(state, 'loan_type') || ['WC'],
  };
};

export const CreateApplicationRoute = connect(mapStateToProps)(CreateApplicationContainer);

export default CreateApplicationRoute;
