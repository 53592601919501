import React, { ReactNode } from 'react';

export const DataError = (
  {
    error,
    msg = 'Experiencing network issues. Please double check your connection.',
  }: { error?: any; msg: string | ReactNode | React.JSX.Element } = {
    msg: <>Unable to retrieve data.</>,
  }
) => {
  const isBackendErrorWhichShouldBeShown = error?.response?.data?.showToUser;
  return (
    <>
      <p className="text-black text-small">
        {isBackendErrorWhichShouldBeShown
          ? error?.response?.data?.message ?? error?.response?.data?.error ?? error?.message ?? error?.error
          : msg}
      </p>
      {!isBackendErrorWhichShouldBeShown && (
        <p className="text-black text-small">If the problem persists you can contact our dev team at hello@tento.co</p>
      )}
      {process.env.NODE_ENV === 'development' && (
        <p style={{ color: 'red' }}>Text in red does not show in production.</p>
      )}
      {process.env.NODE_ENV === 'development' &&
        (error?.response?.status ||
          error?.status ||
          error?.code ||
          error?.response?.statusText ||
          error?.response?.cause) && (
          <p style={{ color: 'red' }}>
            Response status:{' '}
            {process.env.NODE_ENV === 'development'
              ? error?.response?.status ??
                error?.status ??
                error?.response?.cause ??
                error?.response?.statusText ??
                error?.code
              : ''}
          </p>
        )}
      {process.env.NODE_ENV === 'development' && (
        <p style={{ color: 'red' }}>
          Exact error message:{' '}
          {process.env.NODE_ENV === 'development'
            ? error?.response?.data?.message ?? error?.response?.data?.error ?? error?.message ?? error?.error
            : ''}
        </p>
      )}
      {process.env.NODE_ENV === 'development' && (
        <p style={{ color: 'red' }}>Are you sure you have started the node-backend?</p>
      )}
    </>
  );
};
