import React, { useEffect, useLayoutEffect, useState } from 'react';
import GearIcon from '_icons/GearIcon';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import { getDecisionBusiness } from '../../../api/getDecisionBusiness';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { DecisionBusiness, DecisionPersonal } from '../../../../js/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoadingTento } from 'shared-components';

const Process = ({ theme, dataLayerPush, closeAlert, change, history, setTemp, vendorId }) => {
  const flags = useFlags();
  const [{ data: decisionResponse, loading: decisionLoading, error: decisionError }, decisionCheck] =
    getDecisionBusiness();
  const [timeElapsed, setTimeElapsed] = useState(false); // Waits 10s (time for animation to finish and go back.)
  useEffect(() => {
    decisionCheck();
  }, []);

  const routeDecision = ({ decision, fico, offers }) => {
    // close any existing alerts
    closeAlert();

    // send a GTM event
    dataLayerPush('credit-decision', {
      decision,
    });
    analytics.track('Apply Form - Credit Decision', {
      decision,
    });

    // use history.replace so that process cannot be back browsed
    switch (decision) {
      case DecisionBusiness.ProceedEQ:
      case DecisionBusiness.ProceedWC:
      case DecisionPersonal.ProceedEQ:
      case DecisionPersonal.ProceedWC:
      case 'proceed': // Sometimes backend might reply with "proceed" if someone forgot to handle new product type
        if (flags.termsAndDocuSign || process.env.STORYBOOK_TEST) {
          history.replace('/app/agreement/terms');
        } else {
          history.replace('/app/contact-soon');
        }
        break;
      case DecisionBusiness.ProceedPersonal:
        history.replace('/app/decision/personal');
        break;
      case 'offers':
        setTemp({
          pendingOffers: offers,
        });
        history.replace('/app/decision/offers');
        break;
      case DecisionBusiness.DeclinedError:
      case DecisionBusiness.DeclinedTimeInBusiness:
      case DecisionBusiness.DeclinedFico:
      case DecisionBusiness.DeclinedRevenue:
      case DecisionBusiness.DeclinedGeneral:
      case DecisionPersonal.DeclinedError:
      case DecisionPersonal.DeclinedTimeInBusiness:
      case DecisionPersonal.DeclinedFico:
      case DecisionPersonal.DeclinedRevenue:
      case DecisionPersonal.DeclinedGeneral:
        change('application', 'owner_1_fico', fico);
        history.replace('/app/decision/decline');
        break;
      case DecisionBusiness.DeclinedFrozen:
      case DecisionPersonal.DeclinedFrozen:
        history.replace('/app/decision/frozen');
        break;
    }
  };

  useEffect(() => {
    if (decisionResponse && timeElapsed) {
      routeDecision(decisionResponse.data);
    }
  }, [decisionResponse, timeElapsed]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setTimeElapsed(true);
    }, 10000);
  }, []);

  return (
    <>
      {decisionError && (
        <Alert severity="error">
          <AlertTitle>Failed to compute decision</AlertTitle>
          <DataError msg="Unfortunately, we couldn't compute the decision. Please try again." error={decisionError} />
        </Alert>
      )}
      {(decisionLoading || !timeElapsed) && (
        <div className="app-section slide">
          <Helmet>
            <title>Decision Processing - Your Loan Application</title>
          </Helmet>
          <div className="flex flex-1 items-center">
            <LoadingTento width={undefined} height={undefined} />
          </div>
        </div>
      )}
    </>
  );
};

export default Process;
