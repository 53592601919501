import { useAuth0 } from '@auth0/auth0-react';
import { RootState } from '@store/reducers';
import React from 'react';
import { FC, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { ApplyContainer, ApplyWizardContainer, Loader, Text, useResponsiveBreakpoints } from 'shared-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BackButton } from '@components/Apply/BackButton/BackButton';
import { useApplyWizardContext } from '../../context/ApplyWizardContext';

export const ContentCardInternal: FC<
  PropsWithChildren<{
    hideBackArrow?: boolean;
    variant?: 'small' | 'standard' | 'wide' | 'ultra-wide';
    finalWizardRoutes: RootState['wizardRouting']['finalWizardRoutes'];
  }>
> = ({ children, finalWizardRoutes, hideBackArrow }) => {
  const auth = useAuth0();
  const flags = useFlags();
  const responsiveBreakpoints = useResponsiveBreakpoints();

  const { isInWizard, steps, stepDirection, currentPath, currentStepIndex, previousRoute } = useApplyWizardContext();
  const faq =
    currentPath === '/' ||
    currentPath === '/app/amount' ||
    currentPath === '/app/business/further-details' ||
    currentPath === '/app/business/owners' ||
    currentPath === '/app/funding-is-almost-here' ||
    currentPath === '/app/bank-connect' ||
    window.location.pathname === '/app/done'
      ? {
          // FAQ from Griffin at https://docs.google.com/document/d/1MENJwqmHshSy759Pw54jsE0v727c8weMKYnFvLGxR6g/edit?pli=1
          heading: 'Frequently Asked Questions',
          questions: [
            ...(currentPath === '/'
              ? [
                  {
                    question: 'How does Tento work?',
                    answer:
                      'With Tento, it’s never been easier to access capital. We want to help you solve your short and medium-term capital needs, so you can keep building a successful, thriving business in the long-term. That’s why we reward healthy businesses with savings and lower APRs. We offer both working capital and equipment financing based on the health of your business.',
                  },
                  {
                    question: 'Is Tento right for my business?',
                    answer: `Tento is here to help businesses of all sizes and needs. When reviewing your business, our goal is to help get you the best possible offer. The best way to see what Tento can do for your business is to go through our simple application. There's no cost to apply, no obligation to use your offer once approved and no impact to your credit score.`,
                  },
                  {
                    question: 'How does the application work?',
                    answer: `There are no hard credit checks, lengthy paperwork, or required personal financial statements. All you have to do is connect your bank information using Plaid or provide 4 months of bank statements through our secure portal, tell us a few details about yourself and your business and you’ll receive your offer within hours.`,
                  },
                ]
              : []),
            // ...(currentPath === '/app/business/more-details'
            //   ? [
            //       {
            //         question: 'What if the industry of my business isn’t listed?',
            //         answer: 'We don’t want to put you in a box! Please select “other” in the dropdown.',
            //       },
            //     ]
            //   : []),

            ...(currentPath === '/app/amount'
              ? [
                  {
                    question: 'How much capital can I access?',
                    answer:
                      'Tento provides funding from $10K - $500K for (almost) everything your business needs it for.',
                  },
                  {
                    question: 'How do you calculate my offer?',
                    answer: `We take a holistic view of your business using your bank data, reviewing the overall health of your business and historical performance. Other factors we consider when reviewing bank data are average daily balance, total credits, total debits, competitor balances and exposure among others. When you connect your bank data, we will quickly generate your initial offer details.`,
                  },
                  {
                    question: 'Does it cost anything to sign up?',
                    answer: `No! It costs nothing to join Tento and there is absolutely no obligation to accept an offer.  Applying takes just a few minutes. Securely connect your bank, upload your ID and enter a few personal details to find out how much capital you can access.`,
                  },
                ]
              : []),
            ...(currentPath === '/app/business/further-details'
              ? [
                  {
                    question: 'Where do I find Federal Tax ID?',
                    answer: (
                      <>
                        <Text variant="muted" id="find-federal-tax-id-title">
                          If you lost or misplaced your EIN, you can retrieve it in the following ways:
                        </Text>
                        <p>
                          <Text variant="muted" id="find-federal-tax-id-option1">
                            · Reference the original notice issued by the IRS when you received your EIN or call the IRS
                            Business &amp; Specialty Tax Line at 1-800-829-4933.
                          </Text>
                        </p>
                        <p>
                          <Text variant="muted" id="find-federal-tax-id-option2">
                            · If you used it to open a bank account or get a license, contact these organizations they
                            should be able to retrieve your number.
                          </Text>
                        </p>
                        <p>
                          <Text variant="muted" id="find-federal-tax-id-option3">
                            · Find an old tax return. Your EIN should be on it.
                          </Text>
                        </p>
                      </>
                    ),
                  },
                  {
                    question: 'What is my “Gross Revenue”?',
                    answer: 'Gross Revenue is the total sales for your business before any expenses.',
                  },
                ]
              : []),
            ...(currentPath === '/app/bank-connect'
              ? [
                  {
                    question: 'Is my information secure?',
                    answer:
                      'Yes. We take our privacy policy very seriously and never share your information. To learn more about how we store your information securely, you can read our privacy policy here.',
                  },
                  {
                    question: 'Why do I need to connect Plaid?',
                    answer: `A more complete picture of your business will result in better offers and more capital available for your business. Tento assesses your risk level based on secure live data connections. More complete data connections typically mean better ratings and more capital available for your business.`,
                  },
                  {
                    question: 'Is  my credit score impacted?',
                    answer: `No! We’re here to help you build better business credit. The goal is that by using our products, our financing will enhance your credit history with positive payment history.`,
                  },
                ]
              : []),

            ...(currentPath === '/app/business/owners'
              ? [
                  {
                    question: 'Is my information secure?',
                    answer: (
                      <>
                        Yes. We take our privacy policy very seriously. To learn more about how we store your
                        information securely, you can read our privacy policy{' '}
                        <a href="https://www.tento.co/privacy-policy" target="_blank">
                          here
                        </a>
                        .
                      </>
                    ),
                  },
                  {
                    question: 'Why do you need my Social Security Number?',
                    answer:
                      'We collect your social security number to verify your identity and determine eligibility for our financing products.',
                  },
                  {
                    question: `Will Tento's financing report to my personal credit bureau?`,
                    answer: `No, we are a commercial lender and therefore report your payment history to applicable business credit bureaus.`,
                  },
                  {
                    question: 'Should I add additional owners?',
                    answer:
                      'If you are the only owner in the business, then there is no need to add anyone else.  If you have a partner(s) then you will also need to provide their information as well.',
                  },
                ]
              : []),

            ...(currentPath === '/app/funding-is-almost-here'
              ? [
                  {
                    question: 'How long will it take to receive my offer?',
                    answer:
                      'A decision on your financing request typically takes a few hours from the time you submit your application.  In some instances it could take 1-2 business days.',
                  },
                  {
                    question: 'What if the information shown is incorrect?',
                    answer:
                      'No problem, mistakes happen. You can go back into any step of the application to fix errors.',
                  },
                  {
                    question: 'What if I have multiple bank accounts?',
                    answer:
                      'We’d recommend uploading all business bank accounts so we have an accurate picture of your cash flow.',
                  },
                ]
              : []),
            ...(window.location.pathname === '/app/done'
              ? [
                  {
                    question: 'How fast will I receive my capital?',
                    answer:
                      'Once approved and we receive your signed documentation, your business will typically receive funding within 1 to 2 business days. We know your business needs funding now - not later.',
                  },
                  {
                    question: 'Do I need to do anything else?',
                    answer: `Not right now! But feel free to explore more of our financial wellness products below.`,
                  },
                ]
              : []),
          ],
        }
      : undefined;

  if (isInWizard && !flags.withApplyContainerAnimation && !responsiveBreakpoints.isMobile) {
    const Page =
      steps?.[currentStepIndex ?? 0].Component ??
      function () {
        return null;
      };
    return (
      <ApplyContainer
        faq={faq}
        hasCompletedStep={!(window.location.pathname === '/')}
        className={window.location.pathname === '/' ? 'bg-dawn' : ''}
        contentDivProps={{ className: 'items-center justify-center' }}
        childrenBefore={
          flags.applyShowStepNavigationOnTop ? null : (
            <>
              {finalWizardRoutes.some((r) => `/app/${r}` === window.location.pathname) && (
                <BackButton finalWizardRoutes={finalWizardRoutes} hideBackArrow={!!hideBackArrow} />
              )}
            </>
          )
        }
      >
        <Page />
      </ApplyContainer>
    );
  }

  return isInWizard ? (
    <ApplyWizardContainer
      renderNotCurrent={false}
      index={currentStepIndex ?? 0}
      actionType={stepDirection || 'next'}
      steps={
        steps?.map((step) => ({
          id: step.id,
          Component: step.Component,
        })) ?? []
      }
      faq={faq}
      className={currentPath === '/' ? 'bg-dawn' : ''}
      contentDivProps={{ className: 'items-center justify-center' }}
      childrenBefore={
        flags.applyShowStepNavigationOnTop ? null : (
          <>
            {finalWizardRoutes.some((r) => `/app/${r}` === currentPath) && (
              <BackButton finalWizardRoutes={finalWizardRoutes} hideBackArrow={!!hideBackArrow} />
            )}
          </>
        )
      }
      animatePresenceProps={{
        custom: {},
      }}
      applyPastContainerProps={{
        className: 'hover:cursor-pointer',
        onClick: () => {
          console.log('applyPastContainerProps onClick');
          if (previousRoute) {
            previousRoute();
          }
        },
      }}
    />
  ) : (
    <ApplyContainer
      faq={faq}
      hasCompletedStep={!(window.location.pathname === '/')}
      className={window.location.pathname === '/' ? 'bg-dawn' : ''}
      contentDivProps={{ className: 'items-center justify-center' }}
      childrenBefore={
        flags.applyShowStepNavigationOnTop ? null : (
          <>
            {finalWizardRoutes.some((r) => `/app/${r}` === window.location.pathname) && (
              <BackButton finalWizardRoutes={finalWizardRoutes} hideBackArrow={!!hideBackArrow} />
            )}
          </>
        )
      }
    >
      {!auth.isLoading ? children : <Loader />}
    </ApplyContainer>
  );
};

export const ContentCard = connect((state: RootState) => {
  return {
    finalWizardRoutes: state.wizardRouting.finalWizardRoutes,
  };
})(ContentCardInternal);
