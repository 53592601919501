import React, { Component, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { change, reduxForm, Field } from 'redux-form';
import { checkBox, muiCustomSelect, newThemeInput } from '_fields/inputs';
import { required } from '_fields/validation';
import { parseCurrency } from '_fields/parsers';
import ContinueButton from '@components/_shared/Continue/Button';
import FormControl from '@material-ui/core/FormControl';
import { FormPageTopPart } from '../FormPageTopPart/FormPageTopPart';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { Helmet } from 'react-helmet';
import { LoadingTento, Stack, useHasFullLoaderTimeElapsed } from 'shared-components';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withOnPersonalLoanSubmit from '@components/Apply/withOnSubmit/withOnPersonalLoanSubmit';

const styles = {
  paper: {
    padding: '20px',
  },
  popover: {
    pointerEvents: 'none',
  },
  typography: {
    padding: '25px',
    width: '320px',
    fontSize: '12px',
    textAlign: 'justify',
    position: 'relative',
  },
};

const PersonalLoan = ({
  invalid,
  saving,
  history,
  classes,
  fico,
  dataLayerPush,
  handleSubmit,
  loan_id,
  loan_type,
  loan_amount,
}) => {
  const form = useForm();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState();
  const hasFullAnimationCompleted = useHasFullLoaderTimeElapsed();

  const handleClick = (event, popoverId) => {
    setOpenedPopoverId(popoverId);
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };
  const creditRangeSelector = !fico ? (
    <Field
      name="credit_range"
      component={muiCustomSelect}
      label="Personal Credit Range"
      validate={[required]}
      selectValueProps={{ placeholder: 'Choose' }}
      options={[
        { value: 'excellent', text: 'Excellent (720+)' },
        { value: 'good', text: 'Good (660-719)' },
        { value: 'fair', text: 'Fair (620-659)' },
        { value: 'poor', text: 'Poor (below 620)' },
        { value: 'limited', text: 'Limited' },
      ]}
    />
  ) : null;

  return saving && !hasFullAnimationCompleted ? (
    <div className="flex flex-1 items-center">
      <LoadingTento width={undefined} height={undefined} />
    </div>
  ) : (
    <div className="">
      <Helmet>
        <title>Decision Personal Loan - Your Loan Application</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart
          headingText="Additional Information"
          subHeadingText={
            'Please answer the following questions to check financing eligibility to include personal loan options.'
          }
        />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant="content-to-submit-button">
              <Stack gapVariant="input-to-input" templateColumns="1fr" alignItems="center" justifyItems="center">
                <Field
                  name="own_rent"
                  component={muiCustomSelect}
                  label="Own/Rent"
                  validate={[required]}
                  selectValueProps={{ placeholder: 'Choose' }}
                  options={[
                    { value: 'own_with_mortgage', text: 'Own (With Mortgage)' },
                    { value: 'own_outright', text: 'Own (Outright)' },
                    { value: 'rent', text: 'Rent' },
                  ]}
                />

                <Stack
                  flow="column"
                  templateColumns="1fr 1fr"
                  className="gap-2 w-full max-w-[368px]"
                  gapVariant="input-to-input"
                  alignItems="start"
                >
                  <Field
                    name="employment_status"
                    component={muiCustomSelect}
                    label="Employment"
                    validate={[required]}
                    selectValueProps={{ placeholder: 'Choose' }}
                    options={[
                      { value: 'self_employed', text: 'Self Employed' },
                      { value: 'employed_full_time', text: 'Full Time' },
                      { value: 'employed_part_time', text: 'Part Time' },
                      { value: 'military', text: 'Military' },
                      { value: 'retired', text: 'Retired' },
                      { value: 'not_employed', text: 'Unemployed' },
                    ]}
                  />

                  <Field
                    name="pay_frequency"
                    component={muiCustomSelect}
                    label="Pay Frequency"
                    validate={[required]}
                    selectValueProps={{ placeholder: 'Choose' }}
                    options={[
                      { value: 'weekly', text: 'Weekly' },
                      { value: 'biweekly', text: 'Bi-weekly' },
                      { value: 'twice_monthly', text: 'Twice Monthly' },
                      { value: 'monthly', text: 'Monthly' },
                    ]}
                  />
                </Stack>

                <Stack flow="row" templateColumns="1fr" gapVariant="input-to-input" className="w-full max-w-[368px]">
                  <FormControl className="input-100 annual-income">
                    <Field
                      name="annual_income"
                      component={newThemeInput}
                      type="tel"
                      label="Annual Income"
                      validate={[required]}
                      formatAsNumber
                      parse={parseCurrency}
                    />
                  </FormControl>
                  <div className="disclosure-title" onClick={(e) => handleClick(e, 'disclosure-popover')}>
                    Income Disclosure
                  </div>
                </Stack>
                <Popover
                  id="disclosure-popover"
                  open={openedPopoverId === 'disclosure-popover'}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.typography}>
                    Your yearly income before taxes, alimony, child support, or separate maintenance is optional and
                    does not need to be included if you do not wish it to be considered as a basis for repaying the
                    loan. Increase any non-taxable income by 25%.
                    <CloseIcon className="close-popover" onClick={handleClose} />
                  </Typography>
                </Popover>
                <Field
                  name="education"
                  component={muiCustomSelect}
                  label="Education Level"
                  validate={[required]}
                  selectValueProps={{ placeholder: 'Choose' }}
                  options={[
                    { value: 'high_school', text: 'High School' },
                    { value: 'associate', text: 'Associate' },
                    { value: 'bachelors', text: "Bachelor's" },
                    { value: 'masters', text: "Master's" },
                    { value: 'doctorate', text: 'Doctorate' },
                    { value: 'other_grad_degree', text: 'Other Education' },
                  ]}
                />

                {creditRangeSelector}

                <div className="text-left">
                  <Field
                    component={checkBox}
                    type="checkbox"
                    id="even-consent"
                    name="even-consent"
                    validate={[required]}
                    children={
                      <label htmlFor="even-consent">
                        By checking this box, I hereby consent to the{' '}
                        <a href="https://www.tento.co/electronic-consent" target="blank">
                          "E-Sign Agreement"
                        </a>
                        , the{' '}
                        <a href="https://www.tento.co/credit-authorization-agreement" target="blank">
                          "Credit Authorization Agreement"
                        </a>
                        , the{' '}
                        <a href="https://www.tento.co/terms" target="blank">
                          Terms of Service
                        </a>{' '}
                        and{' '}
                        <a href="https://www.tento.co/privacy-policy" target="blank">
                          Privacy Policy
                        </a>
                        , and am providing written consent under the FCRA for tento X Inc, and its partners to obtain
                        consumer report information from my credit profile. I agree to be contacted by tento X Inc and
                        its partners and their affiliated companies via email and/or at the telephone number(s) I have
                        provided above to explore various financial products and services I inquired about, including
                        contact through automatic dialing systems, artificial or pre-recorded voice messaging, or text
                        message. Consent is not required as a condition to utilize tento X Inc, and you may choose to be
                        contacted by an individual customer care representative(s) by calling (800) 614-7505.
                      </label>
                    }
                    className="tento-checkbox !max-w-full"
                    stackProps={{
                      className: 'w-full !max-w-full',
                    }}
                  />
                </div>
              </Stack>
              <ContinueButton invalid={invalid} saving={saving} />
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const PersonalLoanReduxForm = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PersonalLoan);

const selector = formValueSelector('application');

const mapStateToProps = (state, ownProps) => {
  return {
    contactId: selector(state, 'owner_1_id'),
    loan_id: selector(state, 'loan_id'),
    loan_type: selector(state, 'loan_type'),
    loan_amount: selector(state, 'loan_amount'),
    temp: state.temp,
    theme: state.theme,
  };
};

const PersonalLoanReduxState = withOnPersonalLoanSubmit(connect(mapStateToProps)(PersonalLoanReduxForm));

const PersonalLoanWrapped = withStyles(styles)(PersonalLoanReduxState);

export default PersonalLoanWrapped;
