import { getNumberOfOwnersFromFormValues } from '@components/Apply/OwnersMoreDetailsAboutYou/getNumberOfOwners';
import { SSNSchema } from '@components/validation/ssn';
import { RootState } from '@store/reducers';
import { z } from 'zod';

export const STEP_HOMEPAGE = '/';
export const STEP_AMOUNT = 'amount';
export const STEP_INFO = 'info';
export const STEP_VERIFY_LOGIN = 'verify/login';
export const STEP_BUSINESS_MORE_DETAILS = 'business/more-details';
export const STEP_BUSINESS_FURTHER_DETAILS = 'business/further-details';
export const STEP_BUSINESS_OWNERS = 'business/owners';
export const STEP_BANK_CONNECT = 'bank-connect';
export const STEP_TYPE_OF_FUNDING = 'type-of-funding';
export const STEP_SUMMARY = 'funding-is-almost-here';

export const stringWithContent = (message?: string): [(v: string | null | undefined) => boolean, string] => [
  (v: string | null | undefined) => (v ? v.trim().length > 0 : true),
  message || 'Cannot be empty or contain only whitespace characters',
];

export type ApplyWizardStep =
  | typeof STEP_HOMEPAGE
  | typeof STEP_AMOUNT
  | typeof STEP_INFO
  | typeof STEP_VERIFY_LOGIN
  | typeof STEP_BUSINESS_MORE_DETAILS
  | typeof STEP_BUSINESS_FURTHER_DETAILS
  | typeof STEP_BUSINESS_OWNERS
  | typeof STEP_BANK_CONNECT
  | typeof STEP_TYPE_OF_FUNDING
  | typeof STEP_SUMMARY;

export type ApplyWizardStepObject = {
  id: ApplyWizardStep;
  isHidden: boolean;
  isShowingInNavigation: boolean;
  path: string;
  zodSchema: z.ZodSchema<any>;
  isComplete: (state: RootState['form']) => boolean;
  Component: React.ComponentType<Partial<HTMLDivElement>>;
};

export const ApplyWizardSteps: Record<ApplyWizardStep, Omit<ApplyWizardStepObject, 'Component'>> = {
  [STEP_HOMEPAGE]: {
    id: STEP_HOMEPAGE,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/',
    zodSchema: z.object({}),
    isComplete: (state) => true,
  },
  [STEP_AMOUNT]: {
    id: STEP_AMOUNT,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/app/amount',
    zodSchema: z.object({
      loan_amount: z.number().positive(),
      loan_terms: z.number().min(1, 'Please select a loan term.'),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_AMOUNT].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_INFO]: {
    id: STEP_INFO,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/app/info',
    zodSchema: z.object({
      business_name: z
        .string()
        .min(2, 'Please enter a valid business name.')
        .refine(...stringWithContent()),
      owner_1_first: z
        .string()
        .min(2, 'Please enter a valid first name.')
        .refine(...stringWithContent()),
      owner_1_last: z
        .string()
        .min(2, 'Please enter a valid last name.')
        .refine(...stringWithContent()),
      owner_1_mobile: z
        .string()
        .refine((value) => !(value && !/^\d{3}-\d{3}-\d{4}$/i.test(value)), 'Invalid, must be 10 digits')
        .refine(...stringWithContent()),
      owner_1_email: z
        .string()
        .email('Please enter a valid email address.')
        .refine(...stringWithContent()),
      consent: z.boolean().refine((value) => value, 'Consent is required to proceed.'),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_INFO].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_VERIFY_LOGIN]: {
    id: STEP_VERIFY_LOGIN,
    isHidden: false,
    isShowingInNavigation: false,
    path: '/app/verify/login',
    zodSchema: z.object({}),
    isComplete: (state) => {
      try {
        return (
          (state.business_name /* previous step is filled anyhow */ ||
            state.business_street) /* next step is filled anyhow */ &&
          !!ApplyWizardSteps[STEP_VERIFY_LOGIN].zodSchema.parse(state)
        );
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BUSINESS_MORE_DETAILS]: {
    id: STEP_BUSINESS_MORE_DETAILS,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/business/more-details',
    zodSchema: z.object({
      business_street: z
        .string()
        .min(2, 'Please enter a valid street.')
        .refine(...stringWithContent()),
      business_suite: z
        .string()
        .optional()
        .refine(...stringWithContent()),
      business_city: z
        .string()
        .min(2, 'Please enter a valid city.')
        .refine(...stringWithContent()),
      business_state: z
        .string()
        .min(2, 'Please enter a valid state.')
        .refine(...stringWithContent()),
      business_zip: z
        .string()
        .min(2, 'Please enter a valid zip code.')
        .refine(...stringWithContent()),
      home_based: z.boolean().optional(),
      business_website: z
        .string()
        .min(2, 'Please enter a valid website.')
        .optional()
        .refine((value) => {
          if (!((value ?? '').trim().length > 0)) {
            return true;
          }

          // Test the schema with various URLs
          // const urls = [
          //   "tento.co",
          //   "https://chatgpt.com/",
          //   "chatgpt.com",
          //   "www.google.com",
          //   "www.google.com/test",
          //   "tento.co/1/2/index.html",
          //   "12.a223.co",
          //   "http://localhost:3000/app/business/more-details",
          //   "sub.domain.example.com",
          //   "http://sub.domain.example.com/path",
          //   "http://web.com:3000/app/business/more-details"
          // ];
          const urlPattern =
            /^(https?:\/\/)?(([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|localhost)(:\d{1,5})?(\/[a-zA-Z0-9-./]*)?$/;
          return urlPattern.test(value?.trim() ?? '');
        }, 'Please enter a valid website.'),
      business_years: z.number(),
      business_structure: z.string().refine(...stringWithContent()),
      business_naics: z.number(),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BUSINESS_MORE_DETAILS].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BANK_CONNECT]: {
    id: STEP_BANK_CONNECT,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/bank-connect',
    zodSchema: z.object({}),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BANK_CONNECT].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BUSINESS_FURTHER_DETAILS]: {
    id: STEP_BUSINESS_FURTHER_DETAILS,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/business/further-details',
    zodSchema: z.object({
      business_ein: z
        .string()
        .min(2, 'Please enter a valid ein.')
        .refine(...stringWithContent())
        .refine(
          (value) => value && (/^\d{2}-\d{7}$/i.test(value) || /^\d{9}$/i.test(value)),
          'Invalid, must be 9 digits (xx-xxxxxxx)'
        ),
      business_revenue: z.number().positive(),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BUSINESS_FURTHER_DETAILS].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_BUSINESS_OWNERS]: {
    id: STEP_BUSINESS_OWNERS,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/business/owners',
    zodSchema: {
      parse: (data: { [a: string]: any }) => {
        const ownersCount = getNumberOfOwnersFromFormValues(data);
        let schema: { [a: string]: any } = {};
        for (let i = 1; i <= ownersCount; i++) {
          schema[`owner_${i}_ssn`] = SSNSchema.refine(
            (value) => value && /^(\d{3}|\*{3})-(\d{2}|\*{2})-\d{4}$/i.test(value),
            'Invalid, must be xxx-xx-xxxx format'
          ).refine(...stringWithContent());
          schema[`owner_${i}_title`] = z
            .string()
            .min(2, 'Please enter a valid title.')
            .refine(...stringWithContent());
          schema[`owner_${i}_ownership`] = z.number().max(100, 'Ownership percentage must be less or equal to 100.');
          schema[`owner_${i}_dob_day`] = z.number();
          schema[`owner_${i}_dob_month`] = z.number();
          schema[`owner_${i}_dob_year`] = z.number();
          schema[`owner_${i}_street`] = z
            .string()
            .min(2, 'Please enter a valid street.')
            .refine(...stringWithContent());
          schema[`owner_${i}_suite`] = z
            .string()
            .optional()
            .refine(...stringWithContent());
          schema[`owner_${i}_city`] = z
            .string()
            .min(2, 'Please enter a valid city.')
            .refine(...stringWithContent());
          schema[`owner_${i}_state`] = z
            .string()
            .min(2, 'Please enter a valid state.')
            .refine(...stringWithContent());
          schema[`owner_${i}_zip`] = z
            .string()
            .min(2, 'Please enter a valid zip code.')
            .refine(...stringWithContent());
          if (i > 1) {
            schema[`owner_${i}_mobile`] = z
              .string()
              .refine((value) => !(value && !/^\d{3}-\d{3}-\d{4}$/i.test(value)), 'Invalid, must be 10 digits')
              .refine(...stringWithContent());
            schema[`owner_${i}_email`] = z
              .string()
              .email('Please enter a valid email address.')
              .refine(...stringWithContent());
            schema[`owner_${i}_first`] = z
              .string()
              .min(2, 'Please enter a valid first name.')
              .refine(...stringWithContent());
            schema[`owner_${i}_last`] = z
              .string()
              .min(2, 'Please enter a valid last name.')
              .refine(...stringWithContent());
          }
        }
        return z.object(schema).parse(data);
      },
    } as z.ZodSchema,
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_BUSINESS_OWNERS].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_TYPE_OF_FUNDING]: {
    id: STEP_TYPE_OF_FUNDING,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/type-of-funding',
    zodSchema: z.object({
      loan_type: z.array(z.string().refine(...stringWithContent())),
    }),
    isComplete: (state) => {
      try {
        return !!ApplyWizardSteps[STEP_TYPE_OF_FUNDING].zodSchema.parse(state);
      } catch (error) {
        return false;
      }
    },
  },
  [STEP_SUMMARY]: {
    id: STEP_SUMMARY,
    isHidden: false,
    isShowingInNavigation: true,
    path: '/app/funding-is-almost-here',
    zodSchema: z.object({
      business_name: z
        .string()
        .min(2, 'Please enter a valid business name.')
        .refine(...stringWithContent()),
      business_ein: z
        .string()
        .min(2, 'Please enter a valid ein.')
        .refine(...stringWithContent())
        .refine(
          (value) => value && (/^\d{2}-\d{7}$/i.test(value) || /^\d{9}$/i.test(value)),
          'Invalid, must be 9 digits (xx-xxxxxxx)'
        ),
      business_revenue: z.number().positive(),
      loan_amount: z.number().positive(),
      loan_terms: z.number().min(1, 'Please select a loan term.'),
      business_years: z.number(),
    }),
    isComplete: (state) => {
      try {
        return (
          /* previous step is filled anyhow */ state.owner_1_dob_day &&
          state.loan_type &&
          !!ApplyWizardSteps[STEP_HOMEPAGE].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_AMOUNT].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_INFO].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_VERIFY_LOGIN].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_BUSINESS_MORE_DETAILS].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_BUSINESS_FURTHER_DETAILS].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_BUSINESS_OWNERS].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_BANK_CONNECT].zodSchema.parse(state) &&
          !!ApplyWizardSteps[STEP_TYPE_OF_FUNDING].zodSchema.parse(state)
        );
      } catch (error) {
        return false;
      }
    },
  },
};
