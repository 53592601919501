import React, { FC } from 'react';
import { reduxForm, Field } from 'redux-form';
import { checkBox, muiCustomSelect, newThemeInput } from '_fields/inputs';
import { lowerCase } from '_fields/normalizers';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { formValueSelector } from 'redux-form';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { RootState } from '@store/reducers';
import { Spinner } from '@components/Spinner/Spinner';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';
import { NaicsField } from '@components/Apply/MoreDetailsOnYourBusiness/NaicsField';
import { AddressField } from '@components/Apply/MoreDetailsOnYourBusiness/AddressField';
import withOnSubmit from '@components/Apply/withOnSubmit/withOnSubmit';
import { ApplyWizardSteps, STEP_BUSINESS_MORE_DETAILS } from '@components/ApplyWizard/ApplyWizardSteps';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import ensureLoggedIn from '../../../hooks/ensureLoggedIn';

type MoreDetailsOnYourBusinessProps = {
  loanType: string;
  handleSubmit: () => void;
  invalid: boolean;
  years?: number;
  saving?: boolean;
};

const MoreDetailsOnYourBusinessWrapper: FC<MoreDetailsOnYourBusinessProps> = (props) => {
  const auth0 = useAuth0();

  if (auth0.isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" fullWidth>
        <Spinner />
      </Stack>
    );
  }

  return <MoreDetailsOnYourBusinessMainComponent {...props} />;
};

const structure = ['Corporation', 'LLC', 'Partnership', 'Sole Proprietorship'];

const MoreDetailsOnYourBusinessMainComponent: FC<MoreDetailsOnYourBusinessProps> = ({
  handleSubmit,
  invalid,
  saving: savingOnSubmit,
  years,
}) => {
  const { saving } = useApplyWizardContext();
  const form = useForm();

  const moreYearsValue = years && Number(years) > 20 ? Number(years) : 20; // Not entirely sure why this logic was added before, but I kept it for legacy reasons.

  return (
    <div>
      <Helmet>
        <title>More Details on Your Business</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText="More Details on Your Business" />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
                <AddressField />

                <Field
                  component={checkBox}
                  id="home_based"
                  name="home_based"
                  data-testid="consent_check"
                  children="My business is home-based"
                />

                <Field
                  data-testid="business-website"
                  name="business_website"
                  component={newThemeInput}
                  type="text"
                  placeholder="Business Website"
                  normalize={lowerCase}
                  format={(v: string) => v?.trim()}
                />

                <Field
                  name="business_years"
                  component={muiCustomSelect}
                  label="Years in business*"
                  className="tib-select"
                  autoFocus
                  data-testid="business_years"
                  selectValueProps={{ placeholder: 'Years in Business*' }}
                  valueAsNumber
                  options={[
                    { value: 0, text: 'under 1 year' },
                    { value: 1, text: '1 year' },
                    { value: 2, text: '2 years' },
                    { value: 3, text: '3 years' },
                    { value: 4, text: '4 years' },
                    { value: 5, text: '5 years' },
                    { value: 6, text: '6 years' },
                    { value: 7, text: '7 years' },
                    { value: 8, text: '8 years' },
                    { value: 9, text: '9 years' },
                    { value: 10, text: '10 years' },
                    { value: 11, text: '11 years' },
                    { value: 12, text: '12 years' },
                    { value: 13, text: '13 years' },
                    { value: 14, text: '14 years' },
                    { value: 15, text: '15 years' },
                    { value: 16, text: '16 years' },
                    { value: 17, text: '17 years' },
                    { value: 18, text: '18 years' },
                    { value: 19, text: '19 years' },
                    { value: moreYearsValue, text: '20+ years' },
                  ]}
                />
                <Field
                  name="business_structure"
                  component={muiCustomSelect}
                  data-testid={`structure_radio_input`}
                  selectValueProps={{ placeholder: 'Business Structure*' }}
                  options={Object.values(structure).map((option) => ({ value: option, text: option }))}
                />

                <NaicsField />
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton
                  invalid={invalid}
                  saving={saving || savingOnSubmit}
                  showLock
                  data-testid="info_continue"
                />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const MoreDetailsOnYourBusinessWrapper2 = reduxForm<MoreDetailsOnYourBusinessProps, MoreDetailsOnYourBusinessProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(ApplyWizardSteps[STEP_BUSINESS_MORE_DETAILS].zodSchema),
})(MoreDetailsOnYourBusinessWrapper);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    years: appSelector(state, 'business_years'),
  };
};

export const MoreDetailsOnYourBusiness = ensureLoggedIn(
  withOnSubmit(connect(mapStateToProps)(MoreDetailsOnYourBusinessWrapper2), STEP_BUSINESS_MORE_DETAILS)
);
