import React, { ComponentType, FC, useEffect, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { checkBox, muiCustomSelect, newThemeInput } from '_fields/inputs';
import ContinueButton from '@components/_shared/Continue/Button';
import { FormPageTopPart } from '@components/_shared/FormPageTopPart/FormPageTopPart';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { RootState } from '@store/reducers';
import { Spinner } from '@components/Spinner/Spinner';
import { Helmet } from 'react-helmet';
import { Stack } from 'shared-components';
import {
  ApplyWizardSteps,
  STEP_BUSINESS_MORE_DETAILS,
  stringWithContent,
} from '@components/ApplyWizard/ApplyWizardSteps';
import { reduxFormZodValidate } from '@utils/reduxFormZodValidate';
import { useApplyWizardContext } from '../../../context/ApplyWizardContext';
import ensureLoggedIn from '../../../hooks/ensureLoggedIn';
import { phone, lowerCase, words } from '_fields/normalizers';
import { sendContactForm } from '../../../api/sendContactForm';
import { z } from 'zod';
import { DataError } from '../../../api/DataError';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import { contactEmail } from '@utils/platformBasedInfo';

const contactZodSchema = z.object({
  business_name: z
    .string()
    .min(2, 'Please enter a valid business name.')
    .refine(...stringWithContent()),
  owner_1_first: z
    .string()
    .min(2, 'Please enter a valid first name.')
    .refine(...stringWithContent()),
  owner_1_last: z
    .string()
    .min(2, 'Please enter a valid last name.')
    .refine(...stringWithContent()),
  owner_1_mobile: z
    .string()
    .refine((value) => !(value && !/^\d{3}-\d{3}-\d{4}$/i.test(value)), 'Invalid, must be 10 digits')
    .refine(...stringWithContent()),
  owner_1_email: z
    .string()
    .email('Please enter a valid email address.')
    .refine(...stringWithContent()),
  consent: z.boolean().refine((value) => value, 'Consent is required to proceed.'),
  business_number_of_customers: z.string().refine(...stringWithContent()),
});

type ContactProps = {
  invalid: boolean;
  vendor_url: string;
  handleSubmit: () => void;
  saving: boolean;
};

interface WithOnSubmitProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

type ExpectedProps = {};

const getDisplayName = (WrappedComponent: ComponentType<any>): string => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const withOnSubmitContactForm = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const HOC: React.FC<Omit<P, keyof WithOnSubmitProps> & ExpectedProps> = (props) => {
    const [{ data: sendResponse, loading: sending, error: sendError }, triggerSend] = sendContactForm();
    const [isFilled, setIsFilled] = useState(false);

    useEffect(() => {
      if (sendResponse) {
        setIsFilled(true);
      }
    }, [sendResponse]);

    return (
      <>
        {!isFilled && (
          <WrappedComponent
            {...(props as P)}
            onSubmit={async (values: any) => {
              triggerSend({
                data: {
                  businessName: values.business_name,
                  first: values.owner_1_first,
                  last: values.owner_1_last,
                  mobile: values.owner_1_mobile,
                  email: values.owner_1_email,
                  businessNumberOfCustomers: values.business_number_of_customers,
                },
              });
            }}
            saving={sending}
          />
        )}
        {isFilled && (
          <Stack gapPx="30px" fullWidth>
            <FormPageTopPart
              headingText="We’ll Be in Touch Soon!"
              subHeadingText={
                <>
                  Thank you for your interest in becoming a platform partner.
                  <br />A team member will reach out within 1-2 business days.
                  <br />
                  In the meantime, you can email us at <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                </>
              }
            />
          </Stack>
        )}
        {sendError && (
          <Alert severity="error">
            <AlertTitle>Failed to send</AlertTitle>
            <DataError msg="Unfortunately, we couldn't send the form." error={sendError} />
          </Alert>
        )}
      </>
    );
  };

  HOC.displayName = `withOnSubmitContactForm(${getDisplayName(WrappedComponent)})`;

  const mapStateToProps = (state: RootState) => {
    return {};
  };

  return connect(mapStateToProps)(HOC as any);
};

const ContactComponent: FC<ContactProps> = ({ handleSubmit, vendor_url, saving, invalid }) => {
  const form = useForm();
  const privacyLink = `${vendor_url}/privacy-policy`;
  const termsLink = `${vendor_url}/terms-and-conditions`;

  return (
    <div>
      <Helmet>
        <title>Tell Us How To Get in Touch</title>
      </Helmet>
      <Stack gapVariant="head-section-to-content">
        <FormPageTopPart headingText="Tell Us How To Get in Touch" />
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <Stack gapVariant={'content-to-submit-button'}>
              <Stack gapVariant={'input-to-input'} justifyItems="center" templateColumns="1fr" fullWidth>
                <Field
                  name="business_name"
                  component={newThemeInput}
                  type="text"
                  placeholder="Company Name*"
                  normalize={words}
                  label="Business Name*"
                  data-testid="business_name"
                  autoFocus
                />

                <Field
                  name="owner_1_first"
                  component={newThemeInput}
                  type="text"
                  label="First Name*"
                  placeholder="First Name*"
                  normalize={words}
                  data-testid="owner_1_first"
                />
                <Field
                  name="owner_1_last"
                  component={newThemeInput}
                  type="text"
                  label="Last Name*"
                  placeholder="Last Name*"
                  normalize={words}
                  data-testid="owner_1_last"
                />

                <Field
                  name="owner_1_mobile"
                  component={newThemeInput}
                  type="tel"
                  label="Mobile Phone*"
                  placeholder="Mobile Phone*"
                  formatAsPhone
                  data-testid="owner_1_mobile"
                />

                <Field
                  name="owner_1_email"
                  component={newThemeInput}
                  type="email"
                  label={'Email*'}
                  placeholder="Email*"
                  normalize={lowerCase}
                  data-testid="owner_1_email"
                />
                <Field
                  name="business_number_of_customers"
                  component={muiCustomSelect}
                  label="Number of Customers*"
                  autoFocus
                  data-testid="business_number_of_customers"
                  selectValueProps={{ placeholder: 'Number of Customers*' }}
                  options={[
                    { value: 'less_than_500', text: '< 500' },
                    { value: '500to5000', text: '500 - 5,000' },
                    { value: '5000to50000', text: '5,000 - 50,000' },
                    { value: 'more_than_50000', text: '50,000 +' },
                  ]}
                />
                <div className="consent-box">
                  <Field
                    component={checkBox}
                    id="consent"
                    name="consent"
                    data-testid="consent_check"
                    children={
                      <label htmlFor="consent">
                        I agree to the{' '}
                        <a target="_blank" href={termsLink} className="!no-underline !text-moss" tabIndex={-1}>
                          terms and conditions
                        </a>{' '}
                        and{' '}
                        <a target="_blank" href={privacyLink} className="!no-underline !text-moss" tabIndex={-1}>
                          privacy policy
                        </a>
                        .
                      </label>
                    }
                  />
                </div>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <ContinueButton invalid={invalid} saving={saving} showLock data-testid="info_continue" />
              </Stack>
            </Stack>
          </form>
        </Form>
      </Stack>
    </div>
  );
};

const ContactWrapper = reduxForm<ContactProps, ContactProps>({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: reduxFormZodValidate(contactZodSchema),
})(ContactComponent);
const appSelector = formValueSelector('application');

const mapStateToProps = (state: RootState) => {
  return {
    vendor_url: state.brand?.data?.vendor_url,
  };
};

export const Contact = withOnSubmitContactForm(connect(mapStateToProps)(ContactWrapper));
