import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { newThemeInput } from '_fields/inputs';
import { required, phoneNumber, emailAddress } from '_fields/validation';
import { phone, lowerCase, words } from '_fields/normalizers';
import { FormPageTopPart } from '../../../_shared/FormPageTopPart/FormPageTopPart';
import { Helmet } from 'react-helmet';
import ContinueButton from '@components/_shared/Continue/Button';
import { useForm } from 'react-hook-form';
import { Form } from '@components/shadcn/components/ui/form';
import { Stack } from 'shared-components';

const EnhancedOwnerInfo = ({ values, ownerIdx, theme, handleSubmit, match, invalid, saving }) => {
  const continueButton =
    match.params.property === 'add' ? null : (
      <ContinueButton invalid={invalid} saving={saving} data-testid="question-submit-button" />
    );

  const form = useForm();
  /**
   * We are purposefully recalculating this instead of storing in state.
   * When stored in state intital load with invalid values pass validation due
   * to state being set after initial validation check.
   */
  const getOtherOwnerValues = () => {
    let result = {
      phoneNumbers: [],
      emailAddresses: [],
    };

    Object.entries(values).forEach(([key, val]) => {
      // do not add the phone or email for this owner to array
      if (key === `owner_${ownerIdx}_mobile` || key === `owner_${ownerIdx}_email`) {
        return;
      }

      if (key.endsWith('mobile')) {
        result.phoneNumbers.push(val);
      }

      if (key.endsWith('email')) {
        result.emailAddresses.push(val);
      }
    });

    return result;
  };

  const uniqueOwnerPhone = (value) => {
    let check = getOtherOwnerValues();

    const valid =
      value && check.phoneNumbers.includes(value) ? 'Each owner must have a unique phone number' : undefined;

    return valid;
  };

  const uniqueOwnerEmail = (value) => {
    let check = getOtherOwnerValues();

    const valid =
      value && check.emailAddresses.includes(value) ? 'Each owner must have a unique email address' : undefined;

    return valid;
  };

  return (
    <div className="app-section slide">
      <Form {...form}>
        <form onSubmit={handleSubmit}>
          <Stack gapVariant="head-section-to-content">
            <Helmet>
              <title>Owner Basic Information - Your Loan Application</title>
            </Helmet>
            <FormPageTopPart
              headingText={`Owner #${ownerIdx} basic information:`}
              subHeadingText="Enter owner's information to continue"
            />

            <Stack gapVariant="content-to-submit-button">
              <Stack gapVariant="input-to-input">
                <Field
                  name={`owner_${ownerIdx}_first`}
                  data-testid={`owner-${ownerIdx}-first`}
                  component={newThemeInput}
                  placeholder="Enter first name"
                  type="text"
                  labelText="First Name"
                  validate={[required]}
                  labelClassName="infoFormCustomLabel"
                  normalize={words}
                  className="left"
                />

                <Field
                  name={`owner_${ownerIdx}_last`}
                  data-testid={`owner-${ownerIdx}-last`}
                  component={newThemeInput}
                  placeholder="Enter last name"
                  type="text"
                  labelText="Last Name"
                  validate={[required]}
                  labelClassName="infoFormCustomLabel"
                  normalize={words}
                />

                <Field
                  name={`owner_${ownerIdx}_mobile`}
                  data-testid={`owner-${ownerIdx}-mobile`}
                  component={newThemeInput}
                  type="tel"
                  placeholder="Enter phone number"
                  labelText="Mobile Phone"
                  className="input-100"
                  formatAsPhone
                  labelClassName="infoFormCustomLabel"
                  validate={[required, phoneNumber, uniqueOwnerPhone]}
                />

                <Field
                  name={`owner_${ownerIdx}_email`}
                  data-testid={`owner-${ownerIdx}-email`}
                  component={newThemeInput}
                  type="email"
                  placeholder="Enter bussines email"
                  labelText="Email"
                  className="input-100"
                  normalize={lowerCase}
                  labelClassName="infoFormCustomLabel"
                  validate={[required, emailAddress, uniqueOwnerEmail]}
                />
              </Stack>
              {continueButton}
            </Stack>
          </Stack>
        </form>
      </Form>
    </div>
  );
};

const OwnerInfo = reduxForm({
  form: 'application',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  connect((state) => ({
    values: getFormValues('application')(state),
  }))(EnhancedOwnerInfo)
);

export default OwnerInfo;
